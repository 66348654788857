import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Badge from 'react-bootstrap/Badge';

import moment from 'moment';
import ReactDatetime from 'react-datetime';
import { ToastContainer, toast } from 'react-toastify';

import { AppState } from '../../../../store/store';
import Candidate from '../../../../lib/common/models/candidate';
import Settings from '../../../../lib/settings';
import NZDocument, {
  NZDocumentType,
  NZDocumentStatus,
} from '../../../../lib/common/models/nzDocument';

import {UserTypesLabels} from "../../../../lib/common/models/user";

import {
  Button,
  Card,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  CardHeader,
} from 'reactstrap';
import Select from "react-select";

import GradesList from './gradeList';
import CandidateApi from '../../../../api/CandidateApi';
import ApiCaller from '../../../../lib/ApiCaller';
import CustomDropZoneLayout from 'views/components/documents/FileUploaderDropZone';
import { DocumentDownloadContainer } from 'views/components/documents/ExamScriptUploader/compStyle';
import DocumentsApi from 'api/DocumentsApi';
import SyllabusApi from 'api/SyllabusApi';
import { downloadDocument } from 'shared/utils/ApiCommands';
import CandidateCertificatesList from './certificateList';
import DetailLayout from 'layouts/DetailLayout';

const CandidateDetail: React.FC = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const layout = useSelector((state: AppState) => state.session.layout);
  const history = useHistory();
  const [currentCandidate, setCurrentCandidate] = useState<Candidate>();
  const [currentDoc, setCurrentDoc] = useState<NZDocument>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingTip, setLoadingTip] = useState<string>('');

  const medicalReportStatuses = new Map();
  medicalReportStatuses.set(NZDocumentStatus.ACTIVE, 'Approved');
  medicalReportStatuses.set(NZDocumentStatus.PENDING, 'Pending Approval');
  medicalReportStatuses.set(NZDocumentStatus.REVOKED, 'Rejected');

  const medicalReportBadges = new Map();
  medicalReportBadges.set(NZDocumentStatus.ACTIVE, 'success');
  medicalReportBadges.set(NZDocumentStatus.PENDING, 'warning');
  medicalReportBadges.set(NZDocumentStatus.REVOKED, 'danger');

  useEffect(() => {
    setCurrentCandidate(Settings.getCurrentCandidate);
  }, []);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    const candidateInfo = currentCandidate?.toObject();
    if (candidateInfo) {
      candidateInfo[name] = value;
      setCurrentCandidate(new Candidate(candidateInfo));
    }
  };

  const handleToggle = (e: any) => {
    const candidateInfo = currentCandidate?.toObject();
    if (candidateInfo) {
      candidateInfo['special'] = e.target.checked;
      setCurrentCandidate(new Candidate(candidateInfo));
    }
  };

  const onDateChange = (e: any) => {
    if (!loggedUser.isAdmin()) {
      return;
    }

    const info = currentCandidate?.toObject();
    if (info) {
      info['birthDate'] = e.toDate();
      setCurrentCandidate(new Candidate(info));
    }
  };

  const updateCandidate = (e: any) => {
    e.preventDefault();
    if (
      !currentCandidate?.special ||
      (currentCandidate?.special && currentDoc)
    ) {
      if (currentCandidate) {
        const candidateApi = new CandidateApi(new ApiCaller());
        candidateApi
          .updateCandidate(currentCandidate, loggedUser.token)
          .then(() => {
            toast.success('Candidate updated');
          })
          .catch(() => {
            toast.error('Error updating candidate');
          });
      }
    } else {
      toast.error('Upload diagnosis report');
    }
  };

  const onSubmitUploading = useCallback(
    (data) => {
      setCurrentDoc(data);
      const candidateApi = new CandidateApi(new ApiCaller());
      candidateApi.updateCandidate(currentCandidate!!, loggedUser.token).then((res) => {
        setCurrentCandidate(res);
      });
    }, [currentCandidate, loggedUser]);

  useEffect(() => {
    if (currentCandidate?._id) {
      const api = new DocumentsApi(new ApiCaller());
      api
        .getAllByProjectId(currentCandidate._id)
        .then((item: any) => {
          setCurrentDoc(item.files[0]);
        })
        .catch(() => {
          toast.error('No exams scripts found');
        });
      const api2 = new CandidateApi(new ApiCaller());
      api2.getDyslexiaSyllabus(currentCandidate,loggedUser.token).then((s: any) => {
        let syll;
        if (s.code==='') syll={code:'', title:''};
        else syll={...s, title:(s?.code==='S01')?'YDES':'EKES'};
        setDyslexicSyll(syll);
      }).catch(() => {
        toast.error('No syll setted');
      });
    }
  }, [currentCandidate]);

  const close = () => {
    history.push(`/${layout}/candidate/list`);
  };

  const [dyslexicSyll, setDyslexicSyll] = useState<any>({code:''});

  const [syllabus, setSyllabus] = useState<any[]>([]);

  React.useEffect(() => {
    const syllabusApi = new SyllabusApi(new ApiCaller());
    syllabusApi.getAllSyllabus(loggedUser.token).then((syllabusList: any) => {
      setSyllabus(syllabusList.syllabuses);
    })
  }, []);

  const onSyllabusChange = (e: any) => {
    setDyslexicSyll(e);
    const api = new CandidateApi(new ApiCaller());
    api.setDyslexiaSyllabus(currentCandidate, e.code,loggedUser.token);
  };

  return (
    <div className="content">
      <DetailLayout title="Go back">
          {currentCandidate && (
            <Card>
              <Row>
                <Col md="12">
                  <CardHeader>
                    <CardHeader>
                      <h4 className="card-title">Candidate Detail</h4>
                    </CardHeader>
                  </CardHeader>
                  <CardBody>
                    <Form className="form-horizontal" onSubmit={updateCandidate}>
                      <Row>
                        <Label sm="2">Serial Number</Label>
                        <Col sm="10">
                          <FormGroup>
                            <Input
                              value={currentCandidate.serialNumber}
                              onChange={handleChange}
                              type="text"
                              name="serialNumber"
                              placeholder="Serial Number"
                              required
                              disabled={!loggedUser.isAdmin()}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="2">Name</Label>
                        <Col sm="10">
                          <FormGroup>
                            <Input
                              value={currentCandidate.firstName}
                              onChange={handleChange}
                              type="text"
                              name="firstName"
                              placeholder="Name"
                              required
                              disabled={!loggedUser.isAdmin()}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="2">Last Name</Label>
                        <Col sm="10">
                          <FormGroup>
                            <Input
                              value={currentCandidate.lastName}
                              onChange={handleChange}
                              type="text"
                              name="lastName"
                              placeholder="Last Name"
                              required
                              disabled={!loggedUser.isAdmin()}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="2">Email</Label>
                        <Col sm="10">
                          <FormGroup>
                            <Input
                              value={currentCandidate.email}
                              onChange={handleChange}
                              type="text"
                              name="email"
                              placeholder="Email"
                              required
                              disabled={!loggedUser.hasCandidatePermissions()}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="2">Date of Birth</Label>
                        <Col sm="10">
                          <FormGroup>
                            <ReactDatetime
                              inputProps={{ readOnly: !loggedUser.isAdmin() }}
                              timeFormat={false}
                              dateFormat={'DD-MM-YYYY'}
                              onChange={(e: any) => onDateChange(e)}
                              value={moment(currentCandidate?.birthDate).utc()}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="2">Passport No.</Label>
                        <Col sm="10">
                          <FormGroup>
                            <Input
                              value={currentCandidate.passportNumber}
                              onChange={handleChange}
                              type="text"
                              name="passportNumber"
                              placeholder="Passport Number"
                              required
                              disabled={!loggedUser.hasCandidatePermissions()}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="2">Learning Difficulties</Label>
                        <Col sm="10">
                          <FormGroup>
                            <Input
                              checked={currentCandidate.special}
                              onChange={handleToggle}
                              type="checkbox"
                              name="special"
                              disabled={
                                !loggedUser.hasCandidatePermissions() ||
                                currentDoc !== undefined
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      {currentCandidate.special && (

                          <>
                          <Row>
                          <Label sm="2">Diagnosis Report</Label>
                          <Col sm="10">
                            <FormGroup>
                              {currentDoc && (
                                <>
                                  {loggedUser.hasMedicalReportsStatusPermissions() && (
                                    <div>
                                      <Row>
                                        <Col md="auto">
                                          <Badge
                                            pill
                                            variant={
                                              medicalReportBadges.get(currentDoc.status)
                                            }>
                                            {medicalReportStatuses.get(currentDoc.status)}
                                          </Badge>
                                        </Col>
                                        <Col>
                                          {currentDoc.description &&
                                            <p>{currentDoc.description}</p>
                                          }
                                        </Col>
                                      </Row>
                                    </div>
                                  )}
                                  {loggedUser.hasMedicalReportsDownloadingPermissions() && (
                                    <DocumentDownloadContainer
                                      onClick={() =>
                                        downloadDocument(
                                          currentDoc.fileHash,
                                          currentDoc.displayName,
                                          loggedUser.token
                                        )
                                      }>
                                      <p>
                                        {currentDoc?.displayName
                                          ? currentDoc?.displayName
                                          : 'No document uploaded yet'}
                                      </p>
                                    </DocumentDownloadContainer>
                                  )}
                                </>
                              )}
                              {(!currentDoc || currentDoc.status === NZDocumentStatus.REVOKED) &&
                                loggedUser.hasMedicalReportsUploadingPermissions() && (<>
                                <CustomDropZoneLayout
                                  onFinish={onSubmitUploading}
                                  type={NZDocumentType.CANDIDATE_DIAGNOSIS}
                                  projectId={currentCandidate._id}
                                  status={NZDocumentStatus.PENDING}
                                  flowPermissionToUpload={dyslexicSyll?.code!==''}
                                />

                                      </>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>

                          <Row>
                            <Label sm="2">Syllabus</Label>
                            <Col sm="10">
                              {loggedUser.isType(UserTypesLabels.examCentre) ? (
                                  <FormGroup>
                                    <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        name="syllabus"
                                        value={dyslexicSyll}
                                        onChange={onSyllabusChange}
                                        options={syllabus}
                                        getOptionLabel={(item) => item.code + ' ' + item.title}
                                        getOptionValue={(item) => item.code}
                                        placeholder="Choose a syllabus"
                                    />
                                  </FormGroup>
                                  ) :
                                  <Input
                                      value={dyslexicSyll.code + ' ' + dyslexicSyll.title}
                                      onChange={()=>{}}
                                      type="text"
                                      name="Syllabus"
                                      required
                                      disabled={true}
                                  />
                              }
                            </Col>
                          </Row>
                          </>
                      )}

                      <Row>
                        <Label sm="2" />
                        <Col sm="10">
                          {loggedUser.hasCandidatePermissions() && (
                            <Button
                              className="btn-round"
                              color="info"
                              type="submit">
                              Update
                            </Button>
                          )}
                          <Button
                            className="btn-round btn btn-danger"
                            onClick={close}>
                            Close
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Col>
              </Row>
            </Card>
          )}
          <GradesList candidate={currentCandidate} />
          <CandidateCertificatesList candidateId={currentCandidate?._id}  setLoading={setLoading} setLoadingTip={setLoadingTip}/>
          <ToastContainer />
      </DetailLayout>
    </div>
  );
};

export default CandidateDetail;
