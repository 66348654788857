import NavigationExamApi from '../../../../api/NavigationExamApi';
import ApiCaller from '../../../../lib/ApiCaller';
import CancelledExamListTable, {
  ListTable,
  ListTableView,
} from './cancelledExamListTable';
import SyllabusApi from '../../../../api/SyllabusApi';

class CancelledExamListPresenter {
  loggedUser: any;
  navigationExamApi: NavigationExamApi;
  syllabusApi: SyllabusApi;
  table: ListTable;

  constructor(profile: any, private view: ListTableView) {
    this.loggedUser = profile;
    this.table = new CancelledExamListTable(this.loggedUser, view);
    this.navigationExamApi = new NavigationExamApi(
      new ApiCaller(this.loggedUser.token),
    );
    this.syllabusApi = new SyllabusApi(new ApiCaller());
  }

  async getAllExams(): Promise<any> {
    return await this.navigationExamApi.getCancelledExams();
  }

  async getAllSyllabus(): Promise<any> {
    const syllabus: any = await this.syllabusApi.getAllSyllabus(
      this.loggedUser.token,
    );
    return syllabus ? syllabus : [];
  }

  getTableColumns() {
    return this.table.getColumns();
  }

  getTableName(): string {
    return this.table.getName();
  }
}

export default CancelledExamListPresenter;
