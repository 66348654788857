import React from 'react';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
import { Route, Switch } from 'react-router-dom';

import AdminNavbar from 'components/Navbars/AdminNavbar';
import Footer from 'components/Footer/Footer.js';

import SidebarAdmin from '../components/Sidebar/SidebarAdmin';
import { userCustomRoute } from './routes';
import UserProfile from '../views/pages/common/profile';

var ps;

class GenericUserLayout extends React.Component {
  profile;
  routes;
  hiddenRoutes;

  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: 'black',
      activeColor: 'warning',
      sidebarMini: true,
    };
    this.profile = props.profile;
    this.profileRoute = [
      { path: '/user-profile', name: 'My Profile', component: UserProfile },
    ];
    const { routes, hiddenroutes } = userCustomRoute(props.profile);
    this.routes = routes;
    this.hiddenRoutes = hiddenroutes;
  }
  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      document.documentElement.className += ' perfect-scrollbar-on';
      document.documentElement.classList.remove('perfect-scrollbar-off');
      ps = new PerfectScrollbar(this.refs.mainPanel);
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
      document.documentElement.className += ' perfect-scrollbar-off';
      document.documentElement.classList.remove('perfect-scrollbar-on');
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === 'PUSH') {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }

  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) return this.getRoutes(prop.views);
      return (
        <Route
          path={`/${this.profile}` + prop.path}
          component={prop.component}
          key={key}
        />
      );
    });
  };
  getHiddenRoutes = (routes) => {
    return routes.map((prop, key) => {
      return (
        <Route
          path={`/${this.profile}` + prop.path}
          component={prop.component}
          key={key}
        />
      );
    });
  };

  handleActiveClick = (color) => this.setState({ activeColor: color });
  handleBgClick = (color) => this.setState({ backgroundColor: color });
  handleMiniClick = () => {
    if (document.body.classList.contains('sidebar-mini')) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle('sidebar-mini');
  };
  render() {
    return (
      <div className="wrapper">
        <SidebarAdmin
          props={this.props}
          sidebar={this.refs.sidebar}
          routes={this.routes}
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
        />
        <div className="main-panel" ref="mainPanel">
          <AdminNavbar {...this.props} handleMiniClick={this.handleMiniClick} />
          {/* Get Dashboard */}
          <Switch>
            {this.getRoutes([...this.routes])}
            {this.getHiddenRoutes([...this.profileRoute, ...this.hiddenRoutes])}
          </Switch>

          {
            // we don't want the Footer to be rendered on full screen maps page
            this.props.location.pathname.indexOf('full-screen-map') !==
              -1 ? null : (
              <Footer fluid />
            )
          }
        </div>
      </div>
    );
  }
}

export default GenericUserLayout;
