import React, {useRef, useState} from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from 'reactstrap';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';

import { AppState } from '../../../../store/store';
import ApiCaller from '../../../../lib/ApiCaller';
import ExamResourceApi from 'api/ExamResourceApi';
import QuestionApi from '../../../../api/QuestionApi';
import SyllabusApi from 'api/SyllabusApi';

import Question, {
  QuestionStatus,
} from '../../../../lib/common/models/question';
import ExamResource from '../../../../lib/common/models/examResource';
import ExamResourceCollection from '../../../../lib/common/models/examResourceCollection';
import { UserTypesLabels } from '../../../../lib/common/models/user';

import QuestionUploadPictures from './questionImageUpload';
import TinyMCETextEditor from "../../../../components/TinyMCETextEditor";
import DetailLayout from 'layouts/DetailLayout';

const QuestionDetail: React.FC = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const layout = useSelector((state: AppState) => state.session.layout);
  const [currentQuestion, setCurrentQuestion] = useState<Question>(
    Question.prototype.emptyQuestion(),
  );
  const [syllabus, setSyllabus] = useState<any[]>([]);
  const [modules, setModules] = useState<any[]>([]);
  const [topic, setTopics] = useState<any[]>([]);
  const [resources, setResources] = useState<Array<ExamResource>>([]);
  const [description, setDescription] = useState<string>('');
  const [answer, setAnswer] = useState<string>('');
  const [btnGalleryActive, sBtnGalleryActive] = useState<boolean>(false);
  const history = useHistory();
  const [modal, setModal] = useState<boolean>(false);

  React.useEffect(() => {
    const syllabusApi = new SyllabusApi(new ApiCaller());
    syllabusApi.getAllSyllabus(loggedUser.token).then((syll: any) => {
      setSyllabus(syll.syllabuses);
    });
    const examResourceApi = new ExamResourceApi(new ApiCaller(loggedUser.token));
    examResourceApi
      .getAll()
      .then((data: ExamResourceCollection) => {
        setResources(data.resources);
      });
  }, []);

  const resetForm = () => {
    setCurrentQuestion(Question.prototype.emptyQuestion());
  };

  const createQuestionId = (q: Question) => {
    return `${q.syllabus.code}-${q.module.code}-${q.topic.code}-NEW`;
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    const info = currentQuestion?.toObject();
    if (info) {
      info[name] = value;
      setCurrentQuestion(new Question(info));
    }
  };

  const onStatusChange = (e: any) => {
    const qInfo = currentQuestion?.toObject();
    if (qInfo) {
      qInfo['status'] = e.value;
      setCurrentQuestion(new Question(qInfo));
    }
  };

  const onSyllabusChange = (e: any) => {
    const qInfo = currentQuestion?.toObject();
    if (qInfo) {
      qInfo['syllabus'] = e;
      setCurrentQuestion(new Question(qInfo));
      const questionApi = new QuestionApi(new ApiCaller(loggedUser.token));
      questionApi.getModulesFromSyllabus(e._id).then((m: any) => {
        setModules(m.modules);
      });
    }
  };

  const onModuleChange = (e: any) => {
    const qInfo = currentQuestion?.toObject();
    if (qInfo) {
      qInfo['module'] = e;
      setCurrentQuestion(new Question(qInfo));
      const questionApi = new QuestionApi(new ApiCaller(loggedUser.token));
      questionApi.getTopicsFromModule(e._id).then((t: any) => {
        setTopics(t.topics);
      });
    }
  };

  const onTopicChange = (e: any) => {
    const qInfo = currentQuestion?.toObject();
    if (qInfo) {
      qInfo['topic'] = e;
      qInfo['name'] = createQuestionId(qInfo);
      setCurrentQuestion(new Question(qInfo));
    }
  };

  const onResourceChange = (e: any) => {
    const questionInfo = currentQuestion?.toObject();
    if (questionInfo) {
      questionInfo['resources'] = e;
      setCurrentQuestion(new Question(questionInfo));
    }
  };

  const createQuestion = (e: React.FormEvent) => {
    e.preventDefault();
    const question = currentQuestion;

    question.associatedAnswer = answer;
    question.description = description;
    if (currentQuestion.associatedAnswer === '') {
      alert('Associated answer is required');
    } else {
      const questionApi = new QuestionApi(new ApiCaller());
      questionApi
        .addQuestion(question, loggedUser.token)
        .then((question: any) => {
          history.push(`/${layout}/question/list`);
          toast.success('Question created successfully');
        })
        .catch((err) => {
          console.log(err);
          toast.error('Error creating question, please try again');
        });
    }
  };

  const cancel = () => {
    history.push(`/${layout}/question/list`);
  };

  const addImageFromGallery = () => {
    setModal(true);
  };
  const addImageToQuestion = () => {
    setModal(false);
  };
  const imageClick = (event: any) => {
    console.log('clicked on image', event.target, 'at index / nothing');
    setModal(false);
  };
  return (
    <div className="content">
      <DetailLayout title="Go back">
        <Card>
          <CardHeader>
            <h4 className="card-title">Create Question</h4>
          </CardHeader>
          <CardBody>
            <Form className="form-horizontal">
              <Row>
                <Label sm="2">Question ID</Label>
                <Col sm="10">
                  <FormGroup>
                    <Input
                      value={currentQuestion.name}
                      type="text"
                      name="name"
                      disabled={!loggedUser.isAdmin()}
                      onChange={handleChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Label sm="2">Question Description</Label>
                <Col sm="10">
                  <FormGroup>
                    <TinyMCETextEditor
                        value={description}
                        onChange={(e: any) => setDescription(e)}/>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Label sm="2">Associated Answer</Label>
                <Col sm="10">
                  <FormGroup>
                    <TinyMCETextEditor
                        value={answer}
                        onChange={(e: any) => setAnswer(e)}/>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Label sm="2">Syllabus</Label>
                <Col sm="10">
                  <FormGroup>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="syllabus"
                      value={currentQuestion?.syllabus}
                      onChange={onSyllabusChange}
                      options={syllabus}
                      getOptionLabel={(item) => item.code + ' ' + item.title}
                      getOptionValue={(item) => item.code}
                      placeholder="Choose a syllabus"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Label sm="2">Module</Label>
                <Col sm="10">
                  <FormGroup>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="module"
                      value={currentQuestion?.module}
                      onChange={onModuleChange}
                      options={modules}
                      getOptionLabel={(item) => item.code + ' ' + item.title}
                      getOptionValue={(item) => item.code}
                      placeholder="Choose a module"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Label sm="2">Topic</Label>
                <Col sm="10">
                  <FormGroup>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="topic"
                      value={currentQuestion?.topic}
                      onChange={onTopicChange}
                      options={topic}
                      getOptionLabel={(item) => item.code + ' ' + item.title}
                      getOptionValue={(item) => item.code}
                      placeholder="Choose a topic"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Label sm="2">Total Mark</Label>
                <Col sm="10">
                  <FormGroup>
                    <Input
                      value={currentQuestion.topic?.marks}
                      type="number"
                      name="marks"
                      disabled={true}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {loggedUser.isType(UserTypesLabels.questionWriter) ? (
                ' '
              ) : (
                <Row>
                  <Label sm="2">Status</Label>
                  <Col sm="10">
                    <FormGroup>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="status"
                        value={{
                          label: currentQuestion?.status,
                          value: QuestionStatus[currentQuestion?.status],
                        }}
                        onChange={onStatusChange}
                        options={Object.keys(QuestionStatus).map(function (
                          status,
                        ) {
                          return {
                            value: status,
                            label: QuestionStatus[status],
                          };
                        })}
                        placeholder="Status"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}
              <Row>
                <Label sm="2">Resources</Label>
                <Col sm="10">
                  <FormGroup>
                    <Select
                      isMulti
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="resources"
                      value={currentQuestion?.resources}
                      onChange={onResourceChange}
                      options={resources}
                      getOptionLabel={(item) => item.name || ''}
                      getOptionValue={(item) => item.id || ''}
                      placeholder="Choose question resources"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Label sm="2">Annexed Images</Label>
                <Col sm="10">
                  <QuestionUploadPictures questionName={currentQuestion.name} />
                </Col>
              </Row>
              <Row>
                <Label sm="2" />
                <Col sm="10">
                  <Button
                    className="btn-round"
                    color="info"
                    onClick={createQuestion}>
                    Create
                  </Button>
                  <Button className="btn-round btn btn-danger" onClick={cancel}>
                    Cancel
                  </Button>
                  {btnGalleryActive && (
                    <Button
                      className="btn-round btn btn-danger"
                      onClick={addImageFromGallery}>
                      Add Image from Gallery
                    </Button>
                  )}
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </DetailLayout>
      <ToastContainer />
    </div>
  );
};

export default QuestionDetail;
