import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import { DropdownButton, Dropdown } from 'react-bootstrap';
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
  CardTitle,
  Modal,
  ModalBody,
  Table,
} from 'reactstrap';

import ReactTable from '../../../../components/ReactTable/ReactTable';
import { AppState } from '../../../../store/store';
import ApiCaller from '../../../../lib/ApiCaller';
import NavigationExamApi from '../../../../api/NavigationExamApi';
import {
  DEFAULT_TIMEZONE_FORMAT,
  formatDateTime,
  sortDateTable,
} from '../../../../shared/utils/dateTime';
import MYDocument from '../../../../lib/common/models/myDocument';
import Exam, { ExamContentElement } from '../../../../lib/common/models/exam';
import { downloadExamByDocumentId } from '../../../../shared/utils/ApiCommands';

import 'react-toastify/dist/ReactToastify.css';

const ExamDocumentArchive: React.FC = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const [exams, setExams] = useState<MYDocument[]>([]);
  const [isQuestionsModalOpen, setIsQuestionsModalOpen] = useState(false);
  const [currentExam, setCurrentExam] = useState<Exam>();

  useEffect(() => {
    const navigationExamApi = new NavigationExamApi(new ApiCaller());
    navigationExamApi.getArchives(loggedUser.token).then((exams) => {
      setExams(exams);
    });
  }, []);

  const openQuestions = (sourceSlug: string) => {
    const navigationExamApi = new NavigationExamApi(
      new ApiCaller(loggedUser.token),
    );
    navigationExamApi.getExamModelBySlug(sourceSlug).then((exam) => {
      setCurrentExam(exam);
      setIsQuestionsModalOpen(true);
    });
  };

  const getExamQuestions = useCallback(() => {
    if (currentExam && currentExam.examContent.content) {
      return currentExam.examContent.content.map((e: ExamContentElement) => (
        <tr key={e.questionName}>
          <td>{e.questionName}</td>
          <td>{e.topicName}</td>
          <td className="text-right">{e.marker}</td>
        </tr>
      ));
    }
  }, [currentExam]);

  const columns = [
    { Header: 'SERIAL NO.', accessor: (d: any) => d.serialNumber ? d.serialNumber : '---', sortable: true },
    { Header: 'EXAM ID', accessor: (d: any) => d.customName, sortable: true },
    {
      Header: 'EXAM CENTRE',
      accessor: (d: any) => d.customDescription,
      sortable: true,
    },
    { Header: 'STATUS', accessor: (d: any) => d.status, sortable: true },
    {
      Header: 'CREATE DATE',
      accessor: (d: any) =>
        formatDateTime(d.createdAt, DEFAULT_TIMEZONE_FORMAT),
      sortable: true,
      sortType: (a: any, b: any) => sortDateTable(a, b, 'createdAt'),
    },
    {
      Header: 'ACTIONS',
      Cell: (row: any) => (
        <DropdownButton variant="default" title="Actions" size="sm">
          <Dropdown.Item
            onClick={() =>
              downloadExamByDocumentId(
                row.row.original._id,
                row.row.original.customName,
              )
            }>
            <i className="nc-icon nc-cloud-download-93" />
            &nbsp;&nbsp;DOWNLOAD
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => openQuestions(row.row.original.sourceSlug)}>
            <i className="nc-icon nc-bullet-list-67" />
            &nbsp;&nbsp;QUESTIONS
          </Dropdown.Item>
        </DropdownButton>
      ),
    },
  ];

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">{'Exams Archive'}</CardTitle>
              </CardHeader>
              <CardBody>
                {exams && <ReactTable data={exams} columns={columns} />}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </div>
      <Modal isOpen={isQuestionsModalOpen}>
        <div className="modal-header">
          <button
            aria-hidden={true}
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setIsQuestionsModalOpen(false)}>
            <i className="nc-icon nc-simple-remove" />
          </button>
          <h5 className="card-title">Questions</h5>
        </div>
        <ModalBody>
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Topic</th>
                <th className="text-right">Marker</th>
              </tr>
            </thead>
            <tbody>{getExamQuestions()}</tbody>
          </Table>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ExamDocumentArchive;
