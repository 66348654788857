import React, { createRef } from 'react';
import { Provider } from 'react-redux';
import configureStore, { AppState } from './store/store';
import ScrollReveal from './utils/ScrollReveal';
import { withRouter } from 'react-router';
import AppRoute from './utils/AppRoute';

import history from './shared/utils/browserHistory';
import { Route, Switch, Redirect } from 'react-router-dom';
import AdminLayout from 'layouts/Admin.js';

import AuthLayout from 'layouts/Auth.js';
import Login from './views/pages/Login';

import RouteListener from './shared/utils/RouteListener';
import GenericUserLayout from './layouts/GenericUserLayout';
import UserProfile from './views/pages/common/profile';
import ResetPassword from './views/pages/auth/ResetPassword';
import ResetPasswordConfirm from './views/pages/auth/ResetPasswordConfirm';
import AuthVerifier from './middleware/AuthVerifier';

interface IExampleComponentProps {
  location?: any;
  store?: any;
}

const App: React.FC<IExampleComponentProps> = ({ store }) => {
  const scrollRef = createRef<ScrollReveal>();
  const routes = [
    { path: '/', name: 'Home', Component: Login, Transition: 'side' },
  ];

  return (
    <>
      <Provider store={store}>
        <RouteListener />
        <ScrollReveal
          history={history}
          ref={scrollRef}
          children={() => (
            <Switch>
              {routes.map(({ path, Component }, index) => (
                <AppRoute
                  exact
                  path={path}
                  component={Component}
                  layout={undefined}
                  key={index}
                />
              ))}
              <Route
                path="/auth"
                render={(props) => <AuthLayout {...props} />}
              />
              <Route
                path="/admin"
                render={(props) => <AdminLayout {...props} />}
              />
              <Route
                path="/users"
                render={(props) => (
                  <GenericUserLayout {...props} profile="users" />
                )}
              />
              <Route
                path="/navozyme"
                render={(props) => (
                  <GenericUserLayout {...props} profile="navozyme" />
                )}
              />

              <Route
                path="/questionwriter"
                render={(props) => (
                  <GenericUserLayout {...props} profile="questionwriter" />
                )}
              />
              <Route
                path="/questionmoderator"
                render={(props) => (
                  <GenericUserLayout {...props} profile="questionmoderator" />
                )}
              />
              <Route
                path="/mcaexaminer"
                render={(props) => (
                  <GenericUserLayout {...props} profile="mcaexaminer" />
                )}
              />
              <Route
                path="/examcentre"
                render={(props) => (
                  <GenericUserLayout {...props} profile="examcentre" />
                )}
              />
              <Route
                path="/examcentreauditor"
                render={(props) => (
                  <GenericUserLayout {...props} profile="examcentreauditor" />
                )}
              />
              <Route
                path="/iamiexamadmin"
                render={(props) => (
                  <GenericUserLayout {...props} profile="iamiexamadmin" />
                )}
              />
              <Route
                path="/iamiexammanager"
                render={(props) => (
                  <GenericUserLayout {...props} profile="iamiexammanager" />
                )}
              />
              <Route
                path="/mcamarker"
                render={(props) => (
                  <GenericUserLayout {...props} profile="mcamarker" />
                )}
              />
              <Route
                path="/treasurer"
                render={(props) => (
                  <GenericUserLayout {...props} profile="treasurer" />
                )}
              />
              <Route
                path="/auditor"
                render={(props) => (
                  <GenericUserLayout {...props} profile="auditor" />
                )}
              />

              <Route exact path="/login" component={Login} layout="/auth" />
              <Route
                exact
                path="/recovery/new"
                component={ResetPassword}
                layout="/auth"
              />
              <Route
                exact
                path="/recovery/confirm/:email?/:token?"
                component={ResetPasswordConfirm}
                layout="/auth"
              />
              <Route exact path="/user-profile" component={UserProfile} />
              {/* <Redirect to="/login" /> */}
              {/*<Route component={PageError} />*/}
            </Switch>
          )}
        />
        <AuthVerifier />
      </Provider>
    </>
  );
};

const store = configureStore();
export default withRouter((props) => <App store={store} {...props} />);
