import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import ReactDatetime from 'react-datetime';
import moment from 'moment';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
} from 'reactstrap';
import RRule, {rrulestr, Weekday} from 'rrule';
import Select from 'react-select';
import {DropdownButton, Dropdown} from 'react-bootstrap';

import ApiCaller from '../../../../lib/ApiCaller';
import {AppState} from '../../../../store/store';
import QuestionApi from '../../../../api/QuestionApi';
import SyllabusApi from 'api/SyllabusApi';
import ExamScheduleCollection from '../../../../lib/common/models/examScheduleCollection';
import ExamScheduleApi from '../../../../api/ExamScheduleApi';
import ReactTable from '../../../../components/ReactTable/ReactTable';
import ExamSchedule, {
  ExamScheduleTypes,
  ExamScheduleTypesLabels,
} from '../../../../lib/common/models/examSchedule';
import Syllabus from '../../../../lib/common/models/syllabus';
import FilteredDateComponent from '../../../components/common/filteredDateComponent';
import Module from '../../../../lib/common/models/module';

import 'react-toastify/dist/ReactToastify.css';

const NavigationExamSettings: React.FC = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);

  const [syllabus, setSyllabus] = useState<any[]>([]);
  const [modules, setModules] = useState<any[]>([]);
  const [syllabusId, setSyllabusId] = React.useState<Syllabus>();
  const [moduleId, setModuleId] = React.useState<Module>();
  const [schedules, setSchedules] = React.useState<ExamScheduleCollection>(
    new ExamScheduleCollection([]),
  );
  const [date, setDate] = React.useState(new Date());
  const [calendarDate, setCalendarDate] = React.useState(new Date());
  const [ruleType, setRuleType] = React.useState(
    ExamScheduleTypesLabels.ADD_DAY,
  );
  const [weeklyForm, setWeeklyForm] = React.useState({
    dateStart: moment().toDate(),
    dateEnd: null,
    byWeekDay: [RRule.MO],
    byMonthDay: [],
    byMonthDayStr: '',
  });
  const [timeSlots, setTimeSlots] = React.useState<string[]>([]);
  const [selectedTimeSlots, setSelectedTimeSlots] = React.useState<string[]>(
    [],
  );

  const [noDatesAvailable, setNoDatesAvailable] = React.useState<boolean>(false);

  React.useEffect(() => {
    const syllabusApi = new SyllabusApi(new ApiCaller());
    syllabusApi.getAllSyllabus(loggedUser.token).then((syllabusList: any) => {
      setSyllabus(syllabusList.syllabuses);
    });

    setTimeSlots(getTimeSlots());
  }, []);

  React.useEffect(() => {
    if (moduleId?._id) {
      const scheduleApi = new ExamScheduleApi(new ApiCaller(loggedUser.token));
      scheduleApi
        .getScheduleByModule(moduleId?._id)
        .then((scheduleCollection: ExamScheduleCollection) => {
          setSchedules(scheduleCollection);
        });
    }
  }, [moduleId]);

  const onSyllabusChange = (selectedSyllabus: any) => {
    setSyllabusId(selectedSyllabus);
    const questionApi = new QuestionApi(new ApiCaller(loggedUser.token));
    questionApi.getModulesFromSyllabus(selectedSyllabus._id).then((m: any) => {
      setModules(m.modules);
    });
  };

  const onModuleChange = (selectedModule: any) => {
    setModuleId(selectedModule);
  };

  const onDateChange = (e: any) => {
    setDate(e.toDate());
  };

  const onFormDateChange = (e: any, fieldName: string) => {
    setWeeklyForm({
      ...weeklyForm,
      [fieldName]: e.toDate(),
    });
  };

  const onWeekDayChange = (e: any, weekDay: Weekday) => {
    const checked = e.target.checked;
    const list = checked
      ? [...weeklyForm.byWeekDay, weekDay]
      : weeklyForm.byWeekDay.filter((val) => val !== weekDay);
    setWeeklyForm({
      ...weeklyForm,
      byWeekDay: list,
    });
  };

  const onMonthDayChange = (e: any) => {
    let val = e.target.value;

    if (!Array.isArray(val)) {
      val = val.split(/[,\s]+/);
    }
    val = val.filter((v: any) => v);
    const res = val.map((n: any) => parseInt(n, 10));

    setWeeklyForm({
      ...weeklyForm,
      byMonthDayStr: e.target.value,
      byMonthDay: res,
    });
  };

  const onRuleChange = (e: any) => {
    setRuleType(e.value);
  };

  const addRecurring = () => {
    const dateEnd = weeklyForm.dateEnd
      ? weeklyForm.dateEnd
      : new Date(
          weeklyForm.dateStart.getFullYear() + 1,
          weeklyForm.dateStart.getMonth(),
          weeklyForm.dateStart.getDate(),
        );

    const vals =
      ruleType == ExamScheduleTypesLabels.MONTHLY
        ? {
            freq: RRule.MONTHLY,
            dtstart: weeklyForm.dateStart,
            until: dateEnd,
            bymonthday: weeklyForm.byMonthDay,
          }
        : {
            freq: RRule.WEEKLY,
            dtstart: weeklyForm.dateStart,
            until: dateEnd,
            byweekday: weeklyForm.byWeekDay,
          };

    const rule = new RRule(vals);
    createSchedule(rule);
  };

  const addDate = () => {
    if (date !== null) {
      const rule = new RRule({
        freq: RRule.YEARLY,
        count: 1,
        dtstart: date,
      });
      createSchedule(rule);
    }
  };

  const createSchedule = (rule: RRule) => {
    const scheduleApi = new ExamScheduleApi(new ApiCaller());
    scheduleApi
      .createSchedule(
        syllabusId?._id!,
        moduleId?._id!,
        rule.toString(),
        selectedTimeSlots,
        loggedUser.token,
      )
      .then((schedule: ExamSchedule) => {
        setSchedules(
          new ExamScheduleCollection([...schedules.schedules, schedule]),
        );
        setSelectedTimeSlots([]);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const deleteSchedule = (e: any) => {
    const schedule = e.row.original;
    const scheduleApi = new ExamScheduleApi(new ApiCaller());
    scheduleApi.deleteSchedule(schedule.id, loggedUser.token).then(() => {
      const updatedSchedules = new ExamScheduleCollection(
        schedules.schedules.filter((item: any) => item.id !== schedule.id),
      );
      setSchedules(updatedSchedules);
    });
  };

  const columns = [
    // { Header: 'RULE', accessor: 'rrule', sortable: true },
    {
      Header: 'TO TEXT',
      accessor: (d: any) => rrulestr(d.rrule).toText(),
      sortable: true,
    },
    {
      Header: 'TIMESLOTS',
      accessor: (d: any) => d.timeSlots.join(', '),
      sortable: true,
    },
    {
      Header: 'ACTIONS',
      Cell: (row: any) => (
        <DropdownButton variant="default" title="Actions" size="sm">
          <Dropdown.Item onClick={() => deleteSchedule(row)}>
            <i className="nc-icon nc-simple-delete" />
            &nbsp;&nbsp;DELETE
          </Dropdown.Item>
        </DropdownButton>
      ),
    },
  ];

  const getTimeSlots = () => {
    const fromDate = moment().startOf('day');
    const toDate = moment().endOf('day');
    const diff = toDate.diff(fromDate, 'minutes');
    const range = [];
    const date = fromDate.toDate();
    for (let i = 0; i <= diff; i += 15) {
      const time = moment(date).add(i, 'minutes');
      range.push(time.format('HH:mm'));
    }

    return range;
  };

  const onChangeTimeSlots = (e: any) => {
    setSelectedTimeSlots(e?.map((item: any) => item.value));
  };

  return (
    <>
      <div className="content">
        <Card>
          <Row>
            <Col md="12">
              <CardHeader>
                <CardTitle tag="h4">Setup exam timeslots</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Label sm="2">Syllabus</Label>
                  <Col sm="10">
                    <FormGroup>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="syllabus"
                        value={syllabusId}
                        onChange={onSyllabusChange}
                        options={syllabus}
                        getOptionLabel={(item) => item.code + ' ' + item.title}
                        getOptionValue={(item) => item.code}
                        placeholder="Choose a syllabus"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Label sm="2">Module</Label>
                  <Col sm="10">
                    <FormGroup>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="module"
                        onChange={onModuleChange}
                        options={modules}
                        getOptionLabel={(item) => item.code + ' ' + item.title}
                        getOptionValue={(item) => item.code}
                        placeholder="Choose a module"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Label sm="2">Scheduling Rule Type</Label>
                  <Col sm="10">
                    <FormGroup>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="status"
                        value={{
                          label: ExamScheduleTypes[ruleType],
                          value: ruleType,
                        }}
                        onChange={onRuleChange}
                        options={Object.keys(ExamScheduleTypes).map(function (
                          type,
                        ) {
                          return {
                            value: type,
                            label: ExamScheduleTypes[type],
                          };
                        })}
                        placeholder="Rule type"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {ruleType && (
                  <Row>
                    <Label sm="2">Time Slots</Label>
                    <Col sm="10">
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        placeholder="Choose time slots"
                        name="multipleSelect"
                        closeMenuOnSelect={false}
                        isMulti
                        value={selectedTimeSlots?.map((item) => {
                          return {
                            label: item,
                            value: item,
                          };
                        })}
                        onChange={(value) => onChangeTimeSlots(value)}
                        options={[
                          {
                            value: '',
                            label: ' Multiple Options',
                            isDisabled: true,
                          },
                          ...timeSlots.map((item) => {
                            return {
                              label: item,
                              value: item,
                            };
                          }),
                        ]}
                      />
                    </Col>
                  </Row>
                )}
                {(ruleType == ExamScheduleTypesLabels.MONTHLY ||
                  ruleType == ExamScheduleTypesLabels.WEEKLY) && (
                  <>
                    <Row>
                      <Label sm="2">Start date</Label>
                      <Col sm="10">
                        <ReactDatetime
                          inputProps={{
                            className: 'form-control',
                            placeholder: 'Pick Start Date',
                            name: 'Start date',
                          }}
                          timeFormat={'HH:mm'}
                          dateFormat={'DD-MM-YYYY'}
                          onChange={(e: any) =>
                            onFormDateChange(e, 'dateStart')
                          }
                          value={moment(weeklyForm.dateStart)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">Date end</Label>
                      <Col sm="10">
                        <ReactDatetime
                          inputProps={{
                            className: 'form-control',
                            placeholder: 'Pick End Date',
                            name: 'End date',
                          }}
                          timeFormat={'HH:mm'}
                          dateFormat={'DD-MM-YYYY'}
                          onChange={(e: any) => onFormDateChange(e, 'dateEnd')}
                          value={moment(weeklyForm.dateEnd)}
                        />
                        <label className="error">
                          The default value is 1 year duration.
                        </label>
                      </Col>
                    </Row>
                    {ruleType == ExamScheduleTypesLabels.WEEKLY && (
                      <Row>
                        <Label sm="2">Weekdays</Label>
                        <Col sm="10">
                          <FormGroup check inline>
                            <Label check>
                              <Input
                                type="checkbox"
                                defaultChecked
                                onChange={(e: any) =>
                                  onWeekDayChange(e, RRule.MO)
                                }
                              />
                              <span className="form-check-sign" />
                              Monday
                            </Label>
                          </FormGroup>{' '}
                          <FormGroup check inline>
                            <Label check>
                              <Input
                                type="checkbox"
                                onChange={(e: any) =>
                                  onWeekDayChange(e, RRule.TU)
                                }
                              />
                              <span className="form-check-sign" />
                              Tuesday
                            </Label>
                          </FormGroup>{' '}
                          <FormGroup check inline>
                            <Label check>
                              <Input
                                type="checkbox"
                                onChange={(e: any) =>
                                  onWeekDayChange(e, RRule.WE)
                                }
                              />
                              <span className="form-check-sign" />
                              Wednesday
                            </Label>
                          </FormGroup>
                          <FormGroup check inline>
                            <Label check>
                              <Input
                                type="checkbox"
                                onChange={(e: any) =>
                                  onWeekDayChange(e, RRule.TH)
                                }
                              />
                              <span className="form-check-sign" />
                              Thursday
                            </Label>
                          </FormGroup>
                          <FormGroup check inline>
                            <Label check>
                              <Input
                                type="checkbox"
                                onChange={(e: any) =>
                                  onWeekDayChange(e, RRule.FR)
                                }
                              />
                              <span className="form-check-sign" />
                              Friday
                            </Label>
                          </FormGroup>
                          <FormGroup check inline>
                            <Label check>
                              <Input
                                type="checkbox"
                                onChange={(e: any) =>
                                  onWeekDayChange(e, RRule.SA)
                                }
                              />
                              <span className="form-check-sign" />
                              Saturday
                            </Label>
                          </FormGroup>
                          <FormGroup check inline>
                            <Label check>
                              <Input
                                type="checkbox"
                                onChange={(e: any) =>
                                  onWeekDayChange(e, RRule.SU)
                                }
                              />
                              <span className="form-check-sign" />
                              Sunday
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                    {ruleType == ExamScheduleTypesLabels.MONTHLY && (
                      <Row>
                        <Label sm="2">Month days</Label>
                        <Col sm="10">
                          <FormGroup>
                            <Input
                              type="text"
                              value={weeklyForm.byMonthDayStr}
                              onChange={onMonthDayChange}
                            />
                            <FormText color="default" tag="span">
                              Enter month days separated by commas.
                            </FormText>
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Label sm="2" />
                      <Col sm="10">
                        <Button color="primary" onClick={addRecurring}>
                          Add Recurring Date
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
                {ruleType == ExamScheduleTypesLabels.ADD_DAY && (
                  <>
                    <Row>
                      <Label sm="2">Add date</Label>
                      <Col sm="10">
                        <ReactDatetime
                          inputProps={{
                            className: 'form-control',
                            placeholder: 'Pick Date',
                            name: 'Date of exam',
                          }}
                          timeFormat={false}
                          dateFormat={'DD-MM-YYYY'}
                          onChange={(e: any) => onDateChange(e)}
                          value={moment(date)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2" />
                      <Col sm="10">
                        <Button color="primary" onClick={addDate}>
                          Add Date
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
                <Row>
                  <Label sm="2">Schedules</Label>
                  <Col sm="10">
                    <FormGroup>
                      <ReactTable
                        data={schedules.schedules}
                        columns={columns}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Label sm="2">Calendar Preview</Label>
                  <Col sm="10">
                      <FilteredDateComponent
                          date={calendarDate}
                          setDate={setCalendarDate}
                          module={moduleId}
                          setException={setNoDatesAvailable}
                      />
                  </Col>
                </Row>
              </CardBody>
            </Col>
          </Row>
        </Card>
      </div>
    </>
  );
};

export default NavigationExamSettings;
