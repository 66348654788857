import React from 'react';
import { useDispatch } from 'react-redux';
import { DropdownButton, Dropdown } from 'react-bootstrap';

import Settings from '../../../../lib/settings';
import NavigationExamApi from '../../../../api/NavigationExamApi';
import ApiCaller from '../../../../lib/ApiCaller';
import {
  NavigationExamStatus,
  ScriptIntentions,
} from '../../../../lib/common/models/navigationExam';
import { UserTypesLabels } from '../../../../lib/common/models/user';

import {
  sortDateTable,
  DEFAULT_TIMEZONE_FORMAT,
  formatDateTime,
} from '../../../../shared/utils/dateTime';
import {
  downloadInvoiceReceipt,
  downloadExamByExamId,
} from '../../../../shared/utils/ApiCommands';

import MarkerApi from '../../../../api/MarkerApi';

import {
  setCandidates,
  setMarkerGrid,
  setQuestions,
  setResults,
} from 'store/actions/marker/markerActions';

export interface ListTableView {
  history: any;
  // setStatus: any;
  layout: string;
}

export interface ListTable {
  getColumns(): any;
  getName(): string;
}

class NavigationExamListTable implements ListTable {
  loggedUser: any;
  view: ListTableView;
  navigationExamApi: NavigationExamApi;
  markerApi: MarkerApi;
  dispatch: any;
  onGenerateCallback: (exam: any) => void;

  constructor(profile: any, view: ListTableView, onGenerateCallback: (exam: any) => void) {
    this.loggedUser = profile;
    this.view = view;
    this.navigationExamApi = new NavigationExamApi(
      new ApiCaller(profile.token),
    );
    this.markerApi = new MarkerApi(new ApiCaller(profile.token));
    this.dispatch = useDispatch();
    this.onGenerateCallback = onGenerateCallback;
  }

  chooseExam = (cell: any): void => {
    const exam = cell.row.original;
    Settings.setCurrentNavigationExam(exam);
    this.view.history.push(`/${this.view.layout}/navigation_exam/detail`);
  };

  onClickGenerate = (cell: any): void => {
    const exam = cell.row.original;
    Settings.setCurrentNavigationExam(exam);
    this.onGenerateCallback(exam);
  }

  releaseExam = (cell: any): void => {
    const exam = cell.row.original;
    this.navigationExamApi.releaseExam(exam.id).then((msg: any) => {
      this.view.history.push(`/${this.view.layout}/completed_exam/list`);
    });
  };

  cloneFromExam = (cell: any): void => {
    const exam = cell.row.original;
    Settings.setCurrentNavigationExam(exam);
    this.view.history.push(`/${this.view.layout}/navigation_exam/clone`);
  };

  markExam = (cell: any): void => {
    const exam = cell.row.original;
    Settings.setCurrentNavigationExam(exam);
    this.markerApi.getMarkerInitialData(exam.id).then((msg: any) => {
      this.dispatch(setCandidates(msg.candidates));
      this.dispatch(setQuestions(msg.questions));
      this.dispatch(setMarkerGrid(msg.grid));
      this.dispatch(setResults(msg.results));
      let destPath = this.loggedUser.hasMCARole() ? `/navigation_exam/marksmca`:`/navigation_exam/marks`;
      this.view.history.push(`/${this.view.layout}${'/navigation_exam/marks'}`);
    });
  };

  enterCandidates = (cell: any): void => {
    const exam = cell.row.original;
    Settings.setCurrentNavigationExam(exam);
  };

  getName = (): string => {
    return 'Exams List';
  };

  getColumns = (): any => {
    return [
      ...this.getCustomUserColumns(),
      {
        Header: 'ACTIONS',
        Cell: (row: any) => (
          <DropdownButton variant="default" title="Actions" size="sm">
            <Dropdown.Item onClick={() => this.chooseExam(row)}>
              <i className="nc-icon nc-settings" />
              &nbsp;&nbsp;VIEW / EDIT
            </Dropdown.Item>
            {this.loggedUser.hasAdminRole() && row.row.original.isScheduled() && (
              <>
                <Dropdown.Item onClick={() => this.onClickGenerate(row)}>
                  <i className="nc-icon nc-settings-gear-65" />
                  &nbsp;&nbsp;GENERATE EXAM
                </Dropdown.Item>
                <Dropdown.Item onClick={() => this.cloneFromExam(row)}>
                  <i className="nc-icon nc-settings-gear-65" />
                  &nbsp;&nbsp;CLONE FROM EXAM
                </Dropdown.Item>
              </>
            )}
            { (this.loggedUser.type!='mcaExaminer' && this.loggedUser.type!='examCentre'&& this.loggedUser.type!='treasurer' && this.loggedUser.type!='examCentreAuditor') &&
              row.row.original.isMarkingActivated() && (
                <Dropdown.Item onClick={() => this.markExam(row)}>
                  <i className="nc-icon nc-settings-gear-65" />
                  &nbsp;&nbsp;MARKS
                </Dropdown.Item>

              )}
            {(row.row.original.isDeadlineReached() ||
              this.loggedUser.isAdmin()) && ( <>
              <Dropdown.Item
                onClick={() =>
                  downloadInvoiceReceipt(
                    row.row.original,
                    this.loggedUser.token,
                  )
                }>
                <i className="nc-icon nc-cloud-download-93" />
                &nbsp;&nbsp;DOWNLOAD INVOICE
              </Dropdown.Item>
                </>
            )}
            {row.row.original.examId &&
              (row.row.original.isReadyForDownload() ||  this.loggedUser.hasMCARole() ||
                this.loggedUser.hasAdminRole()) && (
                <Dropdown.Item
                  onClick={() =>
                    downloadExamByExamId(
                      row.row.original.examId,
                      row.row.original.getExamNumber(),
                      this.loggedUser.token,
                    )
                  }>
                  <i className="nc-icon nc-cloud-download-93" />
                  &nbsp;&nbsp;DOWNLOAD EXAM
                </Dropdown.Item>
              )}
            {row.row.original.examId &&
              row.row.original.isReadyToRelease() &&
              this.loggedUser.hasAdminRole() && (
                <Dropdown.Item onClick={() => this.releaseExam(row)}>
                  <i className="nc-icon nc-cloud-download-93" />
                  &nbsp;&nbsp;RELEASE EXAM
                </Dropdown.Item>
              )}
          </DropdownButton>
        ),
      },
    ];
  };

  getCustomUserColumns = () => {
    let cols = [];
    const examNumberColumn = {
      Header: 'EXAM NUMBER',
      accessor: (d: any) => (d ? d.getExamNumber() : '-'),
      sortable: true,
    };
    const syllabusColumn = {
      Header: 'SYLLABUS',
      accessor: (d: any) =>
        d.syllabus ? `${d.syllabus.code} - ${d.syllabus.title}` : '',
      sortable: true,
    };
    const moduleColumn = {
      Header: 'MODULE',
      accessor: (d: any) =>
        d.module ? `${d.module.code} - ${d.module.title}` : '',
      sortable: true,
    };
    const dateColumn = {
      Header: 'DATE',
      accessor: (d: any) =>
        formatDateTime(d.date, DEFAULT_TIMEZONE_FORMAT),
      sortable: true,
      sortType: (a: any, b: any) => sortDateTable(a, b, 'date'),
    };
    const scriptIntentionColumn = {
      Header: 'SCRIPT INTENTION',
      accessor: (d: any) =>
        d.scriptIntention ? ScriptIntentions[d.scriptIntention] : '',
      sortable: true,
    };
    const statusColumn = {
      Header: 'STATUS',
      accessor: (d: any) =>
        d.status ? NavigationExamStatus[d.status] : '',
      sortable: true,
    };
    const examCentreColumn = {
      Header: 'EXAM CENTRE',
      accessor: (d: any) =>
        d.examCenterId
          ? `(${d.examCenterId.code}) ${d.examCenterId.name}`
          : '',
      sortable: true,
    };

    switch (this.loggedUser.type) {
      case UserTypesLabels.examCentre:
        cols = [
          examNumberColumn,
          syllabusColumn,
          moduleColumn,
          dateColumn,
          scriptIntentionColumn,
        ];
        break;
      case UserTypesLabels.treasurer:
        cols = [
          examNumberColumn,
          examCentreColumn,
          syllabusColumn,
          moduleColumn,
          dateColumn,
          scriptIntentionColumn,
          statusColumn,
        ];
        break;
      default:
        cols = [
          examNumberColumn,
          examCentreColumn,
          moduleColumn,
          dateColumn,
          scriptIntentionColumn,
          statusColumn,
        ];
        break;
    }

    return cols;
  };
}

export default NavigationExamListTable;
