import React, { useCallback, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ToastContainer } from 'react-toastify';
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
  CardTitle,
  Button,
} from 'reactstrap';
import { DropdownButton, Dropdown } from 'react-bootstrap';

import { AppState } from '../../../../store/store';
import Settings from 'lib/settings';
import ApiCaller from '../../../../lib/ApiCaller';
import ExamResource from '../../../../lib/common/models/examResource';
import ExamResourceCollection from '../../../../lib/common/models/examResourceCollection';
import { downloadDocument } from '../../../../shared/utils/ApiCommands';
import ExamResourceApi from 'api/ExamResourceApi';
import ReactTable from '../../../../components/ReactTable/ReactTable';
import {
  formatDateTime,
  sortDateTable,
} from '../../../../shared/utils/dateTime';
import { stateListNames } from 'store/reducers/listsReducer';
import { useClearListStateOnPageChange, useTableControls } from 'hooks';

import 'react-toastify/dist/ReactToastify.css';

const ExamResourceList: React.FC = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const layout = useSelector((state: AppState) => state.session.layout);

  const [resources, setResources] = useState<ExamResourceCollection>(
    new ExamResourceCollection([]),
  );

  const listName = stateListNames.examResources;
  const {
    filters,
    sorting,
    pagination,
    onChangeFilters,
    onChangeSorting,
    onChangePagination,
  } = useTableControls(listName);

  useClearListStateOnPageChange(listName, [
    "/exam_resource/list",
    "/exam_resource/detail",
    "/exam_resource/create",
  ]);

  const history = useHistory();

  const editResource = useCallback((cell: any) => {
    const resource = cell.row.original;
    Settings.setCurrentExamResource(resource);
    const layout =
      loggedUser.type === 'admin' ? 'navozyme' : loggedUser.type.toLowerCase();
    history.push(`/${layout}/exam_resource/detail`);
  }, []);

  useEffect(() => {
    const examResourcesApi = new ExamResourceApi(new ApiCaller(loggedUser.token));
    examResourcesApi.getAll().then((data) => {
      setResources(data);
    });
  }, []);

  const columns = [
    {
      Header: 'NAME',
      accessor: (data: ExamResource) => data.name,
    },
    {
      Header: 'FILE',
      accessor: (data: ExamResource) => data.resource ? data.resource.displayName : '-',
    },
    {
      Header: 'CREATE DATE',
      accessor: (data: ExamResource) => formatDateTime(data.createdAt),
      sortType: (a: any, b: any) => sortDateTable(a, b, 'createdAt'),
    },
    {
      Header: 'UPDATE DATE',
      accessor: (data: ExamResource) => formatDateTime(data.updatedAt),
      sortType: (a: any, b: any) => sortDateTable(a, b, 'updatedAt'),
    },
    {
      Header: 'ACTIONS',
      Cell: (row: any) => (
        <DropdownButton variant="default" title="Actions" size="sm">
          {loggedUser.hasAdminRole() && (
            <Dropdown.Item onClick={() => editResource(row)}>
              <i className="nc-icon nc-settings" />
              &nbsp;&nbsp;EDIT
            </Dropdown.Item>
          )}
          <Dropdown.Item
            onClick={() =>
              downloadDocument(
                row.row.original.resource.fileHash,
                row.row.original.resource.displayName,
                loggedUser.token,
              )
            }
            disabled={!row.row.original.resource}>
            <i className="nc-icon nc-cloud-download-93" />
            &nbsp;&nbsp;DOWNLOAD RESOURCE
          </Dropdown.Item>
        </DropdownButton>
      ),
    },
  ];

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Exam Resources List</CardTitle>
              </CardHeader>
              <CardBody>
                {loggedUser.hasAdminRole() && (
                  <Row>
                    <Col>
                      <Link
                        to={`/${layout}/exam_resource/create`}
                        className="btn-label">
                        <Button color="info">
                          <span>
                            <i className="nc-icon nc-simple-add" />
                          </span>
                          Create
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                )}
                <ReactTable
                  data={resources?.resources}
                  columns={columns}
                  initialFilters={filters}
                  onChangeFilters={onChangeFilters}
                  initialSorting={sorting}
                  onChangeSorting={onChangeSorting}
                  initialPagination={pagination}
                  onChangePagination={onChangePagination}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </div>
    </>
  );
};

export default ExamResourceList;
