import React from 'react';
import Settings from '../../../../lib/settings';
import QuestionApi from '../../../../api/QuestionApi';
import ApiCaller from '../../../../lib/ApiCaller';
import { Dropdown } from 'react-bootstrap';
import { History } from 'history';

import {
  QuestionActions,
  QuestionStatus,
} from '../../../../lib/common/models/question';

import {
  DEFAULT_TIMEZONE_FORMAT,
  formatDateTime,
  sortDateTable,
} from '../../../../shared/utils/dateTime';
import { UserTypesLabels } from '../../../../lib/common/models/user';

import { typeStatus } from './questionTypeStatuses';
import { ListTypes } from './listTypes';

// what is needed on a table view to interact ??
export interface ListTableView {
  onPrepareAction: Function;
  setStatus: Function;
  history: History;
}

export interface ListTable {
  getColumns(): any;
  getName(action?: string): string;
}

class QuestionListTable {
  loggedUser: any;
  view: ListTableView;
  questionApi: QuestionApi;

  constructor(profile: any, view: ListTableView) {
    this.loggedUser = profile;
    this.view = view;
    this.questionApi = new QuestionApi(new ApiCaller());
  }

  chooseQuestion(cell: any) {
    const question = cell.row.original;
    Settings.setCurrentAdminQuestion(question);
    const layout =
      this.loggedUser.type === 'admin'
        ? 'navozyme'
        : this.loggedUser.type.toLowerCase();
    this.view.history.push(`/${layout}/question/detail`);
  }

  getName(action: string): string {
    return ListTypes[action];
  }

  getCustomUserColumns() {
    let cols = [];

    const creationDateColumn = {
      Header: 'CREATION DATE',
      accessor: (d: any) => formatDateTime(d.date, DEFAULT_TIMEZONE_FORMAT),
      sortable: true,
      sortType: (a: any, b: any) => sortDateTable(a, b, 'date'),
    };
    const userColumn = {
      Header: 'USER',
      accessor: (d: any) => d.createdBy?.name,
      sortable: true,
    };
    const statusColumn = {
      Header: 'STATUS',
      accessor: (d: any) => (d.status ? QuestionStatus[d.status] : '-'),
      sortable: true,
    };
    const moduleColumn = {
      Header: 'MODULE',
      accessor: (d: any) =>
        d.module ? `${d.module.code} - ${d.module.title}` : '',
      sortable: true,
    };
    const totalMarkColumn = {
      Header: 'TOTAL MARK',
      accessor: (d: any) => (d.topic?.marks ? `${d.topic.marks}` : 0),
      sortable: true,
    };
    const syllabusColumn = {
      Header: 'SYLLABUS',
      accessor: (d: any) =>
        d.syllabus ? `${d.syllabus.code} - ${d.syllabus.title}` : '',
      sortable: true,
    };

    switch (this.loggedUser.type) {
      case UserTypesLabels.questionWriter:
      case UserTypesLabels.questionModerator:
        cols = [creationDateColumn, userColumn, statusColumn, totalMarkColumn];
        break;
      case UserTypesLabels.IAMIExamAdmin:
        cols = [
          creationDateColumn,
          statusColumn,
          moduleColumn,
          totalMarkColumn,
        ];
        break;
      case UserTypesLabels.mcaExaminer:
        cols = [creationDateColumn, statusColumn, totalMarkColumn];
        break;
      default:
        cols = [
          creationDateColumn,
          statusColumn,
          syllabusColumn,
          moduleColumn,
          totalMarkColumn,
        ];
    }
    return cols;
  }

  actionCustomFloatingMenu(row: any) {
    const { status, syllabus, id } = row.row.original;
    const activatedAccept =
      typeStatus[this.loggedUser.type][QuestionActions.ACCEPT].includes(status);
    const activatedRevoke =
      typeStatus[this.loggedUser.type][QuestionActions.REVOKE].includes(status);

    return (
      <>
        {activatedAccept && (
          <Dropdown.Item
            onClick={() =>
              this.view.onPrepareAction(
                QuestionActions.ACCEPT,
                id,
                status,
                syllabus.code,
              )
            }>
            <i className="nc-icon nc-air-baloon" />
            &nbsp;&nbsp;ACCEPT
          </Dropdown.Item>
        )}
        {activatedRevoke && (
          <Dropdown.Item
            onClick={() =>
              this.view.onPrepareAction(
                QuestionActions.REVOKE,
                id,
                undefined,
                syllabus.code,
              )
            }>
            <i className="nc-icon nc-simple-delete" />
            &nbsp;&nbsp;REVOKE
          </Dropdown.Item>
        )}
      </>
    );
  }
}

export default QuestionListTable;
