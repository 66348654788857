import SyllabusApi from "api/SyllabusApi";
import NavigationExamApi from "../../../../api/NavigationExamApi";
import ApiCaller from "../../../../lib/ApiCaller";
import CompletedExamListTable, {ListTable, ListTableView} from "./completedExamListTable";

class CompletedExamListPresenter {
    loggedUser: any;
    navigationExamApi: NavigationExamApi;
    table: ListTable;
  syllabusApi: any;

    constructor(profile: any, private view: ListTableView) {
        this.loggedUser = profile;
        this.table = new CompletedExamListTable(this.loggedUser, view);
        this.navigationExamApi = new NavigationExamApi(new ApiCaller(this.loggedUser.token));
        this.syllabusApi = new SyllabusApi(new ApiCaller());
    }

    async getAllSyllabus(): Promise<any> {
      const syllabus: any = await this.syllabusApi.getAllSyllabus(
        this.loggedUser.token,
      );
      return syllabus ? syllabus : [];
    }

    async getAllExamsResults(): Promise<any> {
        return await this.navigationExamApi.getResultsAll();
    }

    getTableColumns() {
        return this.table.getColumns();
    }

    getTableName():string {
        return this.table.getName();
    }
}

export default CompletedExamListPresenter;
