import UserDashboard from "../../views/UserDashboard";
import LockScreen from "../../views/pages/LockScreen.js";
import Login from "../../views/pages/Login";
import Register from "../../views/pages/Register.js";
import RegisterNewsletter from "../../views/pages/common/subscribe";
import Timeline from "../../views/pages/Timeline.js";
import UserProfile from "../../views/pages/common/profile";
import ProfileResetPassword from '../../views/pages/common/profile/ProfileResetPassword';

export const routes = [
  {
    path: "/captain",
    name: "My",
    icon: "nc-icon nc-bank",
    component: UserProfile,

  },
  {
    collapse: true,
    name: "Pages",
    icon: "nc-icon nc-book-bookmark",
    state: "pagesCollapse",
    views: [
      {
        path: "/timeline",
        name: "Timeline",
        mini: "T",
        component: Timeline,

      },
      {
        path: "/dashboard",
        name: "Dashboard",
        mini: "D",
        component: UserDashboard,

      },
      {
        path: "/login",
        name: "Login",
        mini: "L",
        component: Login,

      },
      {
        path: "/register",
        name: "Register",
        mini: "R",
        component: Register,

      },
      {
        path: "/sign-up",
        name: "Sign up",
        mini: "S",
        component: RegisterNewsletter,

      },
      {
        path: "/lock-screen",
        name: "LockScreen",
        mini: "LS",
        component: LockScreen,

      },
      {
        path: "/user-profile",
        name: "User Profile",
        mini: "UP",
        component: UserProfile,

      },
    ],
  },
];

export const hiddenRoutes = [
  {
    path: '/recovery-password/confirm/:token?',
    name: 'Reset Password',
    mini: 'RP',
    component: ProfileResetPassword,
  },
];
