import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { ToastContainer } from 'react-toastify';
import Select from 'react-select';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { DropdownButton, Dropdown } from 'react-bootstrap';

import TextArea from 'antd/lib/input/TextArea';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
  CardTitle,
  ModalHeader,
  ModalBody,
  Modal,
  Label,
  FormGroup,
  Form,
} from 'reactstrap';


import QuestionCollection from 'lib/common/models/questionCollection';
import QuestionApi from 'api/QuestionApi';
import ApiCaller from 'lib/ApiCaller';
import ReactTable from 'components/ReactTable/ReactTable';
import { AppState } from 'store/store';
import ConfirmationModal from 'views/components/confirmationModal/confirmationModal';

import { UserTypesLabels } from 'lib/common/models/user';
import {
  QuestionActions,
  QuestionStatusLabels,
} from 'lib/common/models/question';
import SyllabusCollection from 'lib/common/models/syllabusCollection';
import Syllabus from 'lib/common/models/syllabus';
import Settings from 'lib/settings';

import { getCustomUserColumns } from './questionListCustomColumns';
import ModerationForm from './ModerationForm';
import { getMessages } from './questionListMessages';
import QuestionListPresenter from './questionListPresenter';
import { typeStatus } from './questionTypeStatuses';

import {
  updateListSyllabus
} from 'store/actions/lists/listsActions';
import { ListLabels } from './listTypes';
import { useClearListStateOnPageChange, useTableControls } from 'hooks';

import 'react-toastify/dist/ReactToastify.css';

interface IQuestionListProps {
  listType: string;
}

const QuestionList: React.FC<IQuestionListProps> = ({ listType }) => {
  const listName = listType;

  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const layout = useSelector((state: AppState) => state.session.layout);
  const syllabus = useSelector((state: AppState) => state.lists[listName].syllabus);
  const [questions, updateQuestions] = useState<QuestionCollection>(
    new QuestionCollection([]),
  );
  const [selectedQuestions, updateSelectedQuestions] =
    useState<QuestionCollection>(new QuestionCollection([]));
  const [syllabuses, updateSyllabuses] = useState<SyllabusCollection>(
    new SyllabusCollection([]),
  );

  const history = useHistory();
  const [modal, setModal] = useState<boolean>();
  const [actionModel, setActionModel] = useState<{
    message: string;
    action: string;
    current: string;
  }>({ message: '', action: '', current: '' });

  const [status, setStatus] = useState<boolean>();
  const [comments, setComment] = useState('');
  const [withForm, setWithForm] = useState<boolean>(false);
  const [withComments, setWithComments] = useState<boolean>(true);
  const [isDeletionModalOpened, setIsDeletionModalOpened] =
    useState<boolean>(false);
  const [isArchivingModalOpened, setIsArchivingModalOpened] =
    useState<boolean>(false);
  const [currentQuestionId, setCurrentQuestionId] = useState<string>('');

  const questionApi = new QuestionApi(new ApiCaller());

  const dispatch = useDispatch();

  const {
    filters,
    sorting,
    pagination,
    onChangeFilters,
    onChangeSorting,
    onChangePagination,
  } = useTableControls(listName);

  const onPrepareAction = (
    action: string,
    current: string,
    status?: string,
    syllabusCode?: string,
  ) => {
    const message = getMessages(
      loggedUser.type,
      (action as 'ACCEPT') || 'REVOKE',
      syllabusCode || '',
    );

    setWithForm(
      loggedUser.type === UserTypesLabels.questionModerator &&
        action === QuestionActions.ACCEPT,
    );
    setWithComments(status !== QuestionStatusLabels.IN_MODIFICATION);
    setActionModel({ message, action, current });
    setModal(true);
  };

  const view = {
    onPrepareAction: onPrepareAction,
    history: history,
    setStatus: setStatus,
  };
  const presenter = new QuestionListPresenter(loggedUser, view, listType);

  const openDeletionQuestionModal = useCallback((cell: any) => {
    const question = cell.row.original;
    setIsDeletionModalOpened(true);
    setCurrentQuestionId(question.id);
  }, []);

  const openArchivingQuestionModal = useCallback((cell: any) => {
    const question = cell.row.original;
    setIsArchivingModalOpened(true);
    setCurrentQuestionId(question.id);
  }, []);

  const closeDeletionQuestionModal = useCallback(() => {
    setIsDeletionModalOpened(false);
  }, []);

  const closeArchivingQuestionModal = useCallback(() => {
    setIsArchivingModalOpened(false);
  }, []);

  const deleteQuestion = useCallback(
    (questionId: string) => {
      questionApi.deleteQuestion(questionId, loggedUser.token).then(() => {
        toast.success('Question deleted');
      });
    },
    [loggedUser, questionApi],
  );

  const archiveQuestion = useCallback(
    (questionId: string) => {
      questionApi.archiveQuestion(questionId, loggedUser.token).then(() => {
        toast.success('Question archived');
      });
    },
    [loggedUser, questionApi],
  );

  const chooseQuestion = useCallback(
    (cell: any) => {
      const question = cell.row.original;
      Settings.setCurrentAdminQuestion(question);
      const layout =
        loggedUser.type === 'admin'
          ? 'navozyme'
          : loggedUser.type.toLowerCase();
      view.history.push(`/${layout}/question/detail`);
    },
    [loggedUser, view.history],
  );

  const actionCustomFloatingMenu = useCallback(
    (row: any) => {
      const { status, id, syllabus } = row.row.original;
      const activatedAccept =
        typeStatus[loggedUser.type][QuestionActions.ACCEPT].includes(status);
      const activatedRevoke =
        typeStatus[loggedUser.type][QuestionActions.REVOKE].includes(status);
      return (
        <>
          {activatedAccept && (
            <Dropdown.Item
              onClick={() =>
                view.onPrepareAction(
                  QuestionActions.ACCEPT,
                  id,
                  status,
                  syllabus.code,
                )
              }>
              <i className="nc-icon nc-air-baloon" />
              &nbsp;&nbsp;ACCEPT
            </Dropdown.Item>
          )}
          {activatedRevoke && (
            <Dropdown.Item
              onClick={() =>
                view.onPrepareAction(
                  QuestionActions.REVOKE,
                  id,
                  undefined,
                  syllabus.code,
                )
              }>
              <i className="nc-icon nc-simple-delete" />
              &nbsp;&nbsp;REVOKE
            </Dropdown.Item>
          )}
        </>
      );
    },
    [loggedUser.type, view],
  );

  const getColumns = useMemo(() => {
    return [
      { Header: 'QUESTION ID', accessor: 'name', sortable: true },
      ...getCustomUserColumns(loggedUser),
      {
        Header: 'ACTIONS',
        Cell: (row: any) => {
          return (
            <DropdownButton variant="default" title="Actions" size="sm">
              <Dropdown.Item onClick={() => chooseQuestion(row)}>
                <i className="nc-icon nc-settings" />
                &nbsp;&nbsp;DETAILS
              </Dropdown.Item>
              {loggedUser.hasApprovePermission(row.row.original) &&
                actionCustomFloatingMenu(row)}
              {loggedUser.hasArchiveQuestionPermission() &&
                listName !== ListLabels.archivedQuestions && (
                  <Dropdown.Item
                    onClick={() => openArchivingQuestionModal(row)}>
                    <i className="nc-icon nc-simple-delete" />
                    &nbsp;&nbsp;ARCHIVE
                  </Dropdown.Item>
                )}
              {loggedUser.isAdmin() &&
                listName !== ListLabels.archivedQuestions && (
                  <Dropdown.Item onClick={() => openDeletionQuestionModal(row)}>
                    <i className="nc-icon nc-simple-delete" />
                    &nbsp;&nbsp;DELETE
                  </Dropdown.Item>
                )}
            </DropdownButton>
          );
        },
      },
    ];
  }, [loggedUser, openArchivingQuestionModal, openDeletionQuestionModal]);

  const onSyllabusChange = (selectedSyllabus: any) => {
    const updated = filterQuestions(questions, selectedSyllabus);
    updateSelectedQuestions(new QuestionCollection(updated));

    dispatch(updateListSyllabus(selectedSyllabus, listName));
  };

  const filterQuestions = useCallback(
    (questions: QuestionCollection, selectedSyllabus: Syllabus | undefined) => {
      return selectedSyllabus?._id
        ? questions.questions.filter(
            (q: any) => q.syllabus?._id === selectedSyllabus?._id,
          )
        : questions.questions;
    },
    [],
  );

  useClearListStateOnPageChange(listName, [
    "/question/list",
    "/question/detail",
    "/question/bank",
    "/question/archived",
    "/question/create",
  ]);

  useEffect(() => {
    presenter.getAllSyllabus().then((s) => updateSyllabuses(s));
  }, []);

  useEffect(() => {
    presenter.getMyQuestions().then((q) => {
      updateQuestions(q);
      const filteredQuestions = filterQuestions(q, syllabus);

      updateSelectedQuestions(new QuestionCollection(filteredQuestions));
    });
  }, [isDeletionModalOpened, isArchivingModalOpened, status]);

  const onApproveQuestion = () => {
    presenter
      .triggerAction(actionModel.action, actionModel.current, comments)
      .then(() => {
        setComment('');
        setModal(false);
      });
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  <Row>
                    <Col>{presenter.table.getName(listType)}</Col>
                  </Row>
                </CardTitle>
              </CardHeader>
              {loggedUser.hasAdminRole() && (
                <Col sm="10">
                  <FormGroup>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="syllabus"
                      isClearable={true}
                      onChange={onSyllabusChange}
                      options={syllabuses.syllabuses}
                      getOptionLabel={(item) => item.code + ' ' + item.title}
                      getOptionValue={(item) => item.code}
                      placeholder="Choose a syllabus"
                      value={syllabus}
                    />
                  </FormGroup>
                </Col>
              )}
              <CardBody>
                {(loggedUser.isType(UserTypesLabels.questionWriter) ||
                  loggedUser.hasAdminRole()) &&
                  listType !== ListLabels.questionBank &&
                  listType !== ListLabels.archivedQuestions && (
                    <Row>
                      <Col>
                        <Link
                          to={`/${layout}/question/create`}
                          className="btn-label">
                          <Button color="info">
                            <span>
                              <i className="nc-icon nc-simple-add" />
                            </span>
                            Create
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  )}
                {(!loggedUser.hasAdminRole() || syllabus) && (
                  <ReactTable
                    data={selectedQuestions.questions}
                    columns={getColumns}
                    initialFilters={filters}
                    onChangeFilters={onChangeFilters}
                    initialSorting={sorting}
                    onChangeSorting={onChangeSorting}
                    initialPagination={pagination}
                    onChangePagination={onChangePagination}
                  />
                )}
                {loggedUser.hasAdminRole() && !syllabus && (
                  <h5 className="card-category">
                    Please, select a syllabus to view the information.
                  </h5>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        <ToastContainer />

        <Modal isOpen={modal} size={withForm ? 'xl' : ''} scrollable={true}>
          <ModalHeader>
            <button
              type="button"
              className="close"
              onClick={() => setModal(false)}>
              ×
            </button>
            {actionModel.message}
          </ModalHeader>
          <ModalBody>
            <Form className="form-vertical">
              {withForm && <ModerationForm />}
              {withComments && (
                <Row>
                  <Label sm="3">Comments</Label>
                  <Col sm="9">
                    <FormGroup>
                      <TextArea
                        value={comments}
                        name="comments"
                        onChange={(e: any) => setComment(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}
              <Row className="justify-content-center">
                <Button color="primary" onClick={() => onApproveQuestion()}>
                  Yes
                </Button>
                <Button color="danger" onClick={() => setModal(false)}>
                  No
                </Button>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
        {isDeletionModalOpened && (
          <ConfirmationModal
            title="Deletion confirmation"
            onConfirm={() => {
              deleteQuestion(currentQuestionId);
              closeDeletionQuestionModal();
            }}
            onCancel={closeDeletionQuestionModal}
            text="Are you sure you want to delete this question?"
          />
        )}
        {isArchivingModalOpened && (
          <ConfirmationModal
            title="Archiving confirmation"
            onConfirm={() => {
              archiveQuestion(currentQuestionId);
              closeArchivingQuestionModal();
            }}
            onCancel={closeArchivingQuestionModal}
            text="Are you sure you want to archive this question?"
          />
        )}
      </div>
    </>
  );
};

export default QuestionList;
