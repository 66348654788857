import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
  CardTitle,
} from 'reactstrap';

import Settings from '../../../../lib/settings';
import ApiCaller from '../../../../lib/ApiCaller';
import ReactTable from '../../../../components/ReactTable/ReactTable';
import { AppState } from '../../../../store/store';
import ExamCenterApi from '../../../../api/ExamCenterApi';
import ExamCenterCollection from 'lib/common/models/examCenterCollection';
import { UserTypesLabels } from '../../../../lib/common/models/user';
import { formatCurrency } from '../../../../shared/utils/currency';
import { sortCurrencyTable } from '../../../../shared/utils/dateTime';

import 'react-toastify/dist/ReactToastify.css';

const ExamCenterList: React.FC = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const layout = useSelector((state: AppState) => state.session.layout);
  const [examCenters, updateExamCenters] = React.useState<ExamCenterCollection>(
    new ExamCenterCollection([]),
  );
  const history = useHistory();

  useEffect(() => {
    const examCenterApi = new ExamCenterApi(new ApiCaller(loggedUser.token));
    examCenterApi.getAllExamCenters().then((centers: any) => {
      if (centers && Object.keys(centers).length > 0) {
        updateExamCenters(centers);
      }
    });
  }, [loggedUser.token]);

  const chooseExamCenter: any = (cell: any) => {
    const examCenter = cell.row.original;
    Settings.setCurrentExamCenter(examCenter);
    history.push(`/${layout}/examCenter/detail`);
  };

  const openTransactionView: any = (cell: any) => {
    const examCenter = cell.row.original;
    Settings.setCurrentExamCenter(examCenter);
    history.push(`/${layout}/examCenter/transactions`);
  };

  const getCustomUserColumns = () => {
    let cols: any[];

    const codeColumn = {
      Header: 'CODE',
      accessor: 'code',
      sortable: true,
    };
    const nameColumn = {
      Header: 'NAME',
      accessor: 'name',
      sortable: true,
    };
    const addressColumn = {
      Header: 'ADDRESS',
      accessor: 'address',
      sortable: true,
    };

    switch (loggedUser.type) {
      case UserTypesLabels.admin:
      case UserTypesLabels.IAMIExamAdmin:
      case UserTypesLabels.auditor:
      case UserTypesLabels.treasurer:
        cols = [
          codeColumn,
          nameColumn,
          addressColumn,
          {
            Header: 'BALANCE',
            accessor: (d: any) => formatCurrency(d.balance, 'GBP'),
            sortable: true,
            sortType: (a: any, b: any) => sortCurrencyTable(a, b, 'balance'),
          },
          {
            Header: 'ACTIONS',
            Cell: (row: any) => (
              <DropdownButton variant="default" title="Actions" size="sm">
                {loggedUser.hasExamCenterAdminPermissions() && (
                  <Dropdown.Item onClick={() => chooseExamCenter(row)}>
                    <i className="nc-icon nc-settings" />
                    &nbsp;&nbsp;EDIT
                  </Dropdown.Item>
                )}
                {(loggedUser.isPaymentAdmin() ||
                  loggedUser.isType(UserTypesLabels.auditor)) && (
                  <Dropdown.Item onClick={() => openTransactionView(row)}>
                    <i className="nc-icon nc-zoom-split" />
                    &nbsp;&nbsp;VIEW
                  </Dropdown.Item>
                )}
              </DropdownButton>
            ),
          },
        ];
        break;
      default:
        cols = [codeColumn, nameColumn, addressColumn];
    }

    return cols;
  };

  const columns = [...getCustomUserColumns()];

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Exam Centres List</CardTitle>
              </CardHeader>
              <CardBody>
                {loggedUser.hasExamCenterAdminPermissions() && (
                  <Row>
                    <Col>
                      <Link
                        to={`/${layout}/examCenter/create`}
                        className="btn-label">
                        <Button color="info">
                          <span>
                            <i className="nc-icon nc-simple-add" />
                          </span>
                          Create
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                )}
                <ReactTable
                  data={examCenters?.centers}
                  columns={columns}
                  isLastColumnSortable={
                    !loggedUser.isAdmin() &&
                    !loggedUser.isType(UserTypesLabels.auditor) &&
                    !loggedUser.isType(UserTypesLabels.treasurer)
                  }
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </div>
    </>
  );
};

export default ExamCenterList;
