import { routes as qw, hiddenRoutes as hqw } from './routesQWriter';
import { routes as qm, hiddenRoutes as hqm } from './routesQModerator';
import { routes as ea, hiddenRoutes as hea } from './routesExamAdmin';
import { routes as ec, hiddenRoutes as hec } from './routesExamCentre';
import { routes as eca, hiddenRoutes as heca } from './routesExamCentre';
import { routes as mm, hiddenRoutes as hmm } from './routesMarker';
import { routes as me, hiddenRoutes as hme } from './routesMcaExaminer';
import { routes as u, hiddenRoutes as hu } from './routesUser';
import { routes as na, hiddenRoutes as hna } from './routesNavozyme';
import { routes as tr, hiddenRoutes as hrt } from './routesTreasurer';
import { routes as ad, hiddenRoutes as had } from './routesAuditor';

const routes = {
  questionwriter: qw,
  questionmoderator: qm,
  iamiexamadmin: ea,
  iamiexammanager: ea,
  mcamarker: mm,
  examcentre: ec,
  mcaexaminer: me,
  users: u,
  navozyme: na,
  treasurer: tr,
  auditor: ad,
  examcentreauditor: eca,
};
const hiddenroutes = {
  navozyme: hna,
  examcentre: hec,
  iamiexamadmin: hea,
  iamiexammanager: hea,
  questionwriter: hqw,
  mcaexaminer: hme,
  treasurer: hrt,
  questionmoderator: hqm,
  auditor: had,
  examcentreauditor: heca,
  mcamarker: hmm,
  users: hu,
};

export function userCustomRoute(profile: string): any {
  const hiddenroute = hiddenroutes.hasOwnProperty(profile)
    ? hiddenroutes[profile]
    : [];
  return { routes: routes[profile], hiddenroutes: hiddenroute };
}
