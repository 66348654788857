export const UPDATE_SESSION = 'UPDATE_SESSION';
export const UPDATE_AVATAR = 'UPDATE_AVATAR';
export const UPDATE_JWT = 'UPDATE_JWT;'

export const SET_CURRENT_DOC = 'SET_CURRENT_DOC';
export const SET_CURRENT_EXAM = 'SET_CURRENT_EXAM';
export const GET_DRAFTS_LIST = 'GET_DRAFTS_LIST';
export const GET_SIGNED_LIST = 'GET_SIGNED_LIST';

export const SET_QUESTIONS = 'SET_QUESTIONS';
export const SET_CANDIDATES = 'SET_CANDIDATES';
export const SET_GRID = 'SET_GRID';
export const SET_HISTORICAL_GRID = 'SET_HISTORICAL_GRID';
export const SET_RESULTS = 'SET_RESULTS';

export const UPDATE_LIST_SYLLABUS = 'UPDATE_LIST_SYLLABUS';
export const UPDATE_LIST_FILTERS = 'UPDATE_LIST_FILTERS';
export const UPDATE_LIST_PAGINATION = 'UPDATE_LIST_PAGINATION';
export const UPDATE_LIST_SORTING = 'UPDATE_LIST_SORTING';
export const CLEAR_LIST_STATE = 'CLEAR_LIST_STATE';
