import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { DropdownButton, Dropdown } from 'react-bootstrap';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
  CardTitle,
} from 'reactstrap';

import { AppState } from '../../../../store/store';
import Settings from '../../../../lib/settings';
import UserCollection from '../../../../lib/common/models/userCollection';
import UserApi from '../../../../api/UserApi';
import ApiCaller from '../../../../lib/ApiCaller';
import ReactTable from '../../../../components/ReactTable/ReactTable';
import { UserTypes } from '../../../../lib/common/models/user';

import 'react-toastify/dist/ReactToastify.css';

const UserList: React.FC = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const layout = useSelector((state: AppState) => state.session.layout);
  const [users, updateUsers] = React.useState<UserCollection>(
    new UserCollection([]),
  );
  const history = useHistory();

  useEffect(() => {
    const userApi = new UserApi(new ApiCaller());
    userApi.getAllUsers(loggedUser.token).then((users: any) => {
      if (users) {
        updateUsers(users);
      }
    });
  }, []);

  const chooseUser = (cell: any) => {
    const user = cell.row.original;
    Settings.setCurrentAdminUser(user);
    history.push(`/${layout}/user/detail`);
  };

  const deleteUser = (cell: any) => {
    const user = cell.row.original;

    const userApi = new UserApi(new ApiCaller());
    userApi
      .deleteUser(user._id || '', loggedUser.token)
      .then(() => {
        toast.success('User deleted');
        const updatedCollection = new UserCollection(
          users.users.filter((item: unknown) => item !== user),
        );
        updateUsers(updatedCollection);
      })
      .catch((err) => {
        console.error(err);
        toast.error('Error deleting user');
      });
  };

  const columns = [
    { Header: 'NAME', accessor: 'name', sortable: true },
    { Header: 'EMAIL', accessor: 'email', sortable: true },
    {
      Header: 'TYPE',
      accessor: (d: any) => (d.type ? UserTypes[d.type] : ''),
      sortable: true,
    },
    {
      Header: 'EXAM CENTRE',
      accessor: (d: any) => (d.entityId ? d.entityId?.name : '-'),
      sortable: true,
    },
    {
      Header: 'ACTIONS',
      Cell: (row: any) => (
        <DropdownButton variant="default" title="Actions" size="sm">
          <Dropdown.Item
            disabled={!loggedUser.isAdmin()}
            onClick={() => chooseUser(row)}>
            <i className="nc-icon nc-settings" />
            &nbsp;&nbsp;VIEW
          </Dropdown.Item>
          {loggedUser.isAdmin() && (
            <Dropdown.Item onClick={() => deleteUser(row)}>
              <i className="nc-icon nc-simple-delete" />
              &nbsp;&nbsp;DELETE
            </Dropdown.Item>
          )}
        </DropdownButton>
      ),
    },
  ];

  const renderCreateUserButton = (isUserHaveAccess: boolean) => {
    if (isUserHaveAccess) {
      return (
        <Link to={`/${layout}/user/create`} className="btn-label">
          <Button color="info">
            <span>
              <i className="nc-icon nc-simple-add" />
            </span>
            Create
          </Button>
        </Link>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Users List</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>{renderCreateUserButton(loggedUser.hasCreateUserPermissions())}</Col>
                </Row>
                <ReactTable data={users.users} columns={columns} />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </div>
    </>
  );
};

export default UserList;
