import React, { useState, useEffect, useCallback } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Card, CardHeader, CardBody, Col, Row, CardTitle, FormGroup } from 'reactstrap';
import Select from 'react-select';

import ReactTable from '../../../../components/ReactTable/ReactTable';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../store/store';
import Syllabus from 'lib/common/models/syllabus';
import NavigationExam from 'lib/common/models/navigationExam';
import NavigationExamCollection from 'lib/common/models/navigationExamCollection';
import SyllabusCollection from 'lib/common/models/syllabusCollection';
import CompletedExamListPresenter from './completedExamListPresenter';
import { useHistory } from 'react-router-dom';
import { stateListNames } from 'store/reducers/listsReducer';
import { useClearListStateOnPageChange, useTableControls } from 'hooks';
import { updateListSyllabus } from 'store/actions/lists/listsActions';

const CompletedExamList: React.FC = () => {
  const listName = stateListNames.completedExams;
  const dispatch = useDispatch();

  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const layout = useSelector((state: AppState) => state.session.layout);
  const syllabus = useSelector((state: AppState) => state.lists[listName].syllabus);
  const [status, setStatus] = useState<boolean>();
  const [selectedExams, updateSelectedExams] =
    useState<NavigationExamCollection>(new NavigationExamCollection([]));
  const [syllabuses, updateSyllabuses] = useState<SyllabusCollection>(
    new SyllabusCollection([]),
  );
  const [selectedSyllabus, setSelectedSyllabus] = useState<
    Syllabus | undefined
  >(syllabus);

  const view = { history: useHistory(), layout: layout, setStatus: setStatus };
  const presenter = new CompletedExamListPresenter(loggedUser, view);

  const {
    filters,
    sorting,
    pagination,
    onChangeFilters,
    onChangeSorting,
    onChangePagination,
  } = useTableControls(listName);

  useClearListStateOnPageChange(listName, [
    "/completed_exam/list",
    "/navigation_exam/results",
  ]);

  const syllabusSelectOptions = syllabuses.syllabuses;

  const filterExams = useCallback(
    (
      exams: NavigationExamCollection,
      selectedSyllabus: Syllabus,
    ): NavigationExam[] => {
      return exams.navigationExams.filter(
        (q: any) => q.syllabus?._id === selectedSyllabus?._id,
      );
    },
    [],
  );

  const onSyllabusChange = (selectedSyllabus: any) => {
    setSelectedSyllabus(selectedSyllabus);
    dispatch(updateListSyllabus(selectedSyllabus, listName));
    const updated = filterExams(selectedExams, selectedSyllabus);
    updateSelectedExams(new NavigationExamCollection(updated));
  };

  useEffect(() => {
    presenter.getAllSyllabus().then((s) => updateSyllabuses(s));
  }, []);

  useEffect(() => {
    presenter.getAllExamsResults().then((exams) => {
      if (selectedSyllabus) {
        const filteredExams = filterExams(exams, selectedSyllabus);
        updateSelectedExams(new NavigationExamCollection(filteredExams));
      } else {
        updateSelectedExams(exams);
      }
    });
  }, [status, selectedSyllabus, filterExams]);

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">{presenter.getTableName()}</CardTitle>
              </CardHeader>
              {loggedUser.hasAdminRole() && (
                <Col sm="10">
                  <FormGroup>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="syllabus"
                      isClearable={true}
                      onChange={onSyllabusChange}
                      options={syllabusSelectOptions}
                      getOptionLabel={(item) => item.code + ' ' + item.title}
                      getOptionValue={(item) => item.code}
                      placeholder="Choose a syllabus"
                      value={selectedSyllabus}
                    />
                  </FormGroup>
                </Col>
              )}
              <CardBody>
                <ReactTable
                  data={selectedExams?.navigationExams}
                  columns={presenter.getTableColumns()}
                  initialFilters={filters}
                  onChangeFilters={onChangeFilters}
                  initialSorting={sorting}
                  onChangeSorting={onChangeSorting}
                  initialPagination={pagination}
                  onChangePagination={onChangePagination}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </div>
    </>
  );
};

export default CompletedExamList;
