import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { AppState } from '../../../store/store';

import PositionSummaryReport from '../../components/dashboard/finance/PositionSummaryReport';
import PaymentsReport from '../../components/dashboard/finance/PaymentsReport';
import InvoiceReport from '../../components/dashboard/finance/InvoiceReport';
import StatementReport from '../../components/dashboard/finance/StatementReport';

import MarkerPaymentsReport from '../../components/dashboard/markerPayments/markerPaymentsCommonReport/MarkerPaymentsReport';
import MarkerPaymentApi from 'api/MarkerPaymentApi';
import { IMarkerPaymentReport } from '../../../lib/common/reports/markerPaymentReport';

import QuestionPayments from '../../components/dashboard/questionPayments';
import QuestionPaymentApi from "../../../api/QuestionPaymentApi";
import { IQuestionPaymentReport } from '../../../lib/common/reports/questionPaymentReport';

import ExamPaymentsReport from '../../components/dashboard/examPayments/ExamPaymentsReport';

const TreasurerDashboard: React.FC = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const [markerPayments, setMarkerPayments] = useState<IMarkerPaymentReport>({});
  const [questionPayments, setQuestionPayments] = useState<IQuestionPaymentReport>({});

  const getMarkerPayments = useCallback(() => {
    MarkerPaymentApi.getNonPaidMarkerPaymentsReport(loggedUser.token).then(
      (data: IMarkerPaymentReport) => {
        setMarkerPayments(data);
      },
    );
  }, [loggedUser]);

  const getQuestionPayments = useCallback(() => {
    QuestionPaymentApi.getNonPaidQuestionPaymentsReport(loggedUser.token).then((data: IQuestionPaymentReport) => {
      setQuestionPayments(data);
    })
  }, [loggedUser]);

  useEffect(() => {
    if (!loggedUser?.isEmpty()) {
      getMarkerPayments();
      getQuestionPayments();
    }
  }, [loggedUser]);

  return (
    <>
      <div className="content">
        <Row>
          <Col>
            <PositionSummaryReport />
          </Col>
        </Row>
        <Row>
          <Col>
            <PaymentsReport />
          </Col>
        </Row>
        <Row>
          <Col>
            <InvoiceReport />
          </Col>
        </Row>
        <Row>
          <Col>
            <StatementReport />
          </Col>
        </Row>
        <Row>
          <Col>
            <MarkerPaymentsReport
              data={markerPayments}
              loggedUser={loggedUser}
              updateMarkerPayments={getMarkerPayments}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <QuestionPayments loggedUser={loggedUser} data={questionPayments} refreshQuestionPayments={getQuestionPayments}/>
          </Col>
        </Row>
        <Row>
          <Col>
            <ExamPaymentsReport loggedUser={loggedUser} />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default TreasurerDashboard;
