import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Label,
    FormGroup,
    Form,
    Input,
    Col,
    Row,
    CardTitle,
} from "reactstrap";

import ApiCaller from "../../../../lib/ApiCaller";
import SyllabusApi from "../../../../api/SyllabusApi"
import { useSelector } from "react-redux";
import { AppState } from "../../../../store/store";

const CreateSyllabus: React.FC = () => {
    const loggedUser = useSelector((state: AppState) => state.session.userInfo);
    const [form, setState] = React.useState({
        code: '',
        title: '',
    });

    const resetForm = () => {
        setState({
            code: '',
            title: '',
        })
    };

    const handleChange = (e:any) => {
        setState({
            ...form,
            [e.target.name]: e.target.value
        });
    };


    const createSyllabus = (e: React.FormEvent) => {
        e.preventDefault();
        var syllabusApi = new SyllabusApi(new ApiCaller());
        syllabusApi.create(form.code, form.title, loggedUser.token).then((user: any) => {
            if (user) {
                toast.success(' Syllabus created successfully');
                resetForm();
            }
        }).catch((err: any) => {
            console.log(err);
            toast.error('Error creating syllabus, please try again');
        });
    };

    return(
        <>
            <div className="content">
                <Card>
                    <Row>
                        <Col md="12">
                            <CardHeader>
                                <CardTitle tag="h4">Create Syllabus</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Form className="form-horizontal" onSubmit={createSyllabus}>
                                    <Row>
                                        <Label sm="2">Code</Label>
                                        <Col sm="10">
                                            <FormGroup>
                                                <Input
                                                    value={form.code}
                                                    onChange={handleChange}
                                                    type="text"
                                                    name="code"
                                                    placeholder="Code"
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Label sm="2">Title</Label>
                                        <Col sm="10">
                                            <FormGroup>
                                                <Input
                                                    value={form.title}
                                                    onChange={handleChange}
                                                    type="text"
                                                    name="title"
                                                    placeholder="Title"
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Label sm="2"></Label>
                                        <Col sm="10">
                                            <Button className="btn-round" color="info" type="submit">
                                                Create
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Col>
                    </Row>
                </Card>
                <ToastContainer/>
            </div>
        </>
    )
}

export default CreateSyllabus;
