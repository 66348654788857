import QuestionApi from '../../../../api/QuestionApi';
import ApiCaller from '../../../../lib/ApiCaller';

import SyllabusApi from '../../../../api/SyllabusApi';
import QuestionListTable, { ListTableView } from './QuestionListTable';

class QuestionListPresenter {
  loggedUser: any;
  questionApi: QuestionApi;
  syllabusApi: SyllabusApi;
  table: any;
  action: string;

  constructor(profile: any, private view: ListTableView, action: string) {
    this.loggedUser = profile;
    this.questionApi = new QuestionApi(new ApiCaller(this.loggedUser.token));
    this.syllabusApi = new SyllabusApi(new ApiCaller());
    this.table = new QuestionListTable(this.loggedUser, view);
    this.action = action;
  }

  async getMyQuestions(): Promise<any> {
    const questions: any = await this.questionApi.getMyQuestions(this.action);
    return questions ? { ...questions, date: questions.questions.map } : {};
  }

  async getPipelineQuestions(): Promise<any> {
    const questions: any = await this.questionApi.getQuestionsInPipeline();
    return questions ? { ...questions, date: questions.questions.map } : {};
  }

  async getAllSyllabus(): Promise<any> {
    const syllabus: any = await this.syllabusApi.getAllSyllabus(
      this.loggedUser.token,
    );
    return syllabus ? syllabus : [];
  }

  async triggerAction(
    action: string,
    questionId: string,
    comments: string,
  ): Promise<boolean> {
    this.view.setStatus(false);
    await this.questionApi.triggerAction(
      action,
      questionId,
      this.loggedUser.type,
    );
    if (!comments || comments.trim() !== '') {
      await this.questionApi.addComment(questionId, comments, action);
    }
    this.view.setStatus(true);
    return true;
  }
}

export default QuestionListPresenter;
