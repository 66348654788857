import DashboardApi from 'api/DashboardApi';
import ModuleApi from 'api/ModuleApi';
import SyllabusApi from 'api/SyllabusApi';
import ApiCaller from 'lib/ApiCaller';
import Module from 'lib/common/models/module';
import Syllabus from 'lib/common/models/syllabus';
import { AvgMarkPerMarkerReportType } from 'lib/common/types/avgMarkPerMarkerReport';
import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';

const useMarks = () => {
  const [modules, setModules] = useState<Module[]>([]);
  const [syllabusSelected, setSyllabusSelected] = useState<string>('S01');
  const [syllabus, setSyllabus] = useState<Syllabus[]>([]);
  const [listSyllabusEnable, setListSyllabusEnable] = useState<string[]>([]);
  const [moduleId, setModuleId] = useState<string | null>(null);
  const [syllabusId, setSyllabusId] = useState<string | null>(null);
  const [report, setReport] = useState<AvgMarkPerMarkerReportType>({});
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const moduleApi = new ModuleApi(new ApiCaller(loggedUser.token));

  useEffect(() => {
    if (syllabusId) {
      moduleApi.getAllModules(syllabusId).then((modules: any) => {
        setModules(modules.modules);
      });
    }
  }, [syllabusId]);

  const getAvg = () => {
    DashboardApi.getAvgMarkPerMarkerReport(loggedUser.token)
      .then((result) => {
        setReport(result);
      })
      .catch((error) => {
        throw new Error(error);
      });
  };

  const getSyallabus = () => {
    const syllabusApi = new SyllabusApi(new ApiCaller());
    syllabusApi.getAllSyllabus(loggedUser.token).then((syllabusList: any) => {
      setSyllabus(syllabusList.syllabuses);
      const listSyllabusCode = syllabusList.syllabuses.map(
        (sy: any) => sy.code,
      );
      if (listSyllabusCode.length > 0) {
        setSyllabusSelected(listSyllabusCode[0]);
        setListSyllabusEnable(listSyllabusCode);
      }
    });
  };

  useEffect(() => {
    getAvg();
    getSyallabus();
  }, [loggedUser.token]);

  useEffect(() => {
    if (syllabus.length > 0) {
      getSyllabusId(syllabusSelected);
    }
  }, [syllabus]);

  const getSyllabusId = (syl: string) => {
    const s = syllabus.find((sy: any) => sy.code === syl);
    if (s) {
      setSyllabusId(s?._id || null);
    }
  };

  const chooseSyllabus = useCallback((syllabu: string) => {
    setModuleId(null);
    getSyllabusId(syllabu);
    setSyllabusSelected(syllabu);
  }, []);

  const onSetModule = (mod: Module | null) => {
    if (mod && mod?._id) {
      setModuleId(mod?._id);
    } else {
      setModuleId(null);
    }
  };

  let labels: any = [];
  let data: any = [];

  if (moduleId) {
    labels = report[syllabusSelected]
      ?.filter((av: any) => av.module === moduleId)
      ?.map((marker: any) => marker.name);
    data = report[syllabusSelected]
      ?.filter((av: any) => av.module === moduleId)
      ?.map((marker: any) => marker.avg);
  } else {
    const groupByName = {};

    report[syllabusSelected]?.forEach((item: any) => {
      const name = item.name;
      const avg = item.avg;

      if (groupByName.hasOwnProperty(name)) {
        groupByName[name].sum += avg;
        groupByName[name].count++;
      } else {
        groupByName[name] = {
          sum: avg,
          count: 1,
        };
      }
    });

    for (const name in groupByName) {
      if (groupByName.hasOwnProperty(name)) {
        const { sum, count } = groupByName[name];
        const average = sum / count;
        labels.push(name);
        data.push(Number(average.toFixed(2)));
      }
    }
  }

  return {
    modules,
    report,
    syllabus: syllabusSelected,
    chooseSyllabus,
    labels,
    data,
    onSetModule,
    listSyllabusEnable,
  };
};

export default useMarks;
