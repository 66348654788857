import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import AuthApi from "api/AuthApi";
import ApiCaller from 'lib/ApiCaller';

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Form,
    Input,
    InputGroupText,
    InputGroup,
    Container,
    Col,
    Row, CardTitle
} from "reactstrap";

import { generateErrors, is } from "shared/utils/validations";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Settings from "lib/settings";

interface RouterProps {
    match: any
}

const ProfileResetPassword: React.FC<RouterProps> = ({ match }) => {
    const user = Settings.getCurrentUser();
    const inputs = ['password'];
    const history = useHistory();
    const [success, setSuccess] = useState<boolean>(false);
    const [error, setError] = useState("");
    const [newPassword, setNewPassword] = useState({
        password: '',
        passwordConfirm: ''
    });
    const [fieldState, setFieldState] = useState({
        passwordState: "",
        passwordConfirmState: ""
    });
    const email = user.email;

    const resetPassword = (e: React.FormEvent) => {
        e.preventDefault();

        const password = newPassword.password
        const code = match.params?.token;

        const errors: any = generateErrors(
            { password },
            { password: [is.required(), is.minLength(3), is.maxLength(30), is.securePassword()] }
        )

        if (errors.password) {
            toast.error(errors.password);
        }

        if (Object.keys(errors).length !== 0) {
            return;
        }

        const authApi = new AuthApi(new ApiCaller());
        authApi.changePassword(email, newPassword.password, code).then((data: any) => {
            if (data.status === "ok") {
                setSuccess(true);
            } else {
                setError(data.status);
            }
        }).catch(err => {
            console.error(err);
            setError(err.message);
        });

        e.preventDefault();
    }

    const goToProfile = () => {
        const profile = user.type === 'admin' ? 'navozyme' : user.type;
        history.push(`/${profile}/user-profile`);
    }

    const passwordOnChange = (e: any) => {
        const password = e.target.value;

        const errors: any = generateErrors(
            { password },
            { password: [is.securePassword()] }
        )

        const newState = (!errors.password) ? 'has-success' : 'has-danger';
        const confirmNewState = e.target.value == newPassword.passwordConfirm ? 'has-success' : 'has-danger';

        setFieldState({
            "passwordState": newState,
            "passwordConfirmState": confirmNewState,
        })

        setNewPassword({
            ...newPassword,
            [e.target.name]: e.target.value
        })
    }

    const confirmPasswordChange = (e: any) => {
        const confirmPassword = e.target.value;

        const confirmNewState = confirmPassword == newPassword.password ? 'has-success' : 'has-danger'

        setFieldState({
            ...fieldState,
            "passwordConfirmState": confirmNewState
        });

        setNewPassword({
            ...newPassword,
            [e.target.name]: confirmPassword
        })
    }

    return (<>
        <div className="content">
            <Container>
                <Row className="justify-content-md-center">
                    <Col md="5">
                        <Card className="card-signup text-center">
                            <CardHeader>
                                <CardTitle tag="h4">Reset your password</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Form action="" className="form" method="">
                                    <InputGroup className={`has-label ${fieldState["passwordState"]}`}>
                                        <InputGroup addonType="prepend">
                                            <InputGroupText>
                                                <i className="nc-icon nc-single-02" />
                                            </InputGroupText>
                                        </InputGroup>
                                        <Input
                                            id="password"
                                            placeholder="Enter your new password"
                                            name="password"
                                            type="password"
                                            autoComplete="off"
                                            value={newPassword.password}
                                            onChange={(e) => passwordOnChange(e)}
                                        />
                                    </InputGroup>
                                    <InputGroup className={`has-label ${fieldState["passwordConfirmState"]}`}>
                                        <InputGroup addonType="prepend">
                                            <InputGroupText>
                                                <i className="nc-icon nc-single-02" />
                                            </InputGroupText>
                                        </InputGroup>
                                        <Input
                                            id="confirmPassword"
                                            placeholder="Confirm your new password"
                                            name="passwordConfirm"
                                            type="password"
                                            autoComplete="off"
                                            value={newPassword.passwordConfirm}
                                            onChange={(e) => confirmPasswordChange(e)}
                                        />
                                        {fieldState["passwordState"] === "has-danger" ? (
                                            <label className="error">Password should contain 8 characters with at least an upper case, a number character and one symbol (#$@!%&*?).</label>
                                        ) : null}
                                        {fieldState["passwordConfirmState"] === "has-danger" ? (
                                            <label className="error">Password should match.</label>
                                        ) : null}
                                    </InputGroup>
                                </Form>
                                {success &&
                                    <ReactBSAlert
                                        success
                                        style={{ display: "block", marginTop: "-100px" }}
                                        title="Password Reset Success"
                                        onConfirm={() => goToProfile()}
                                        onCancel={() => goToProfile()}
                                        confirmBtnBsStyle="success"
                                        btnSize=""
                                        confirmBtnText="Go To Profile"
                                    >
                                        Your password has been updated correctly.
                                    </ReactBSAlert>
                                }
                                {error && (
                                    <ReactBSAlert
                                        danger
                                        style={{ display: "block", marginTop: "-100px" }}
                                        title="Error updating password"
                                        onConfirm={() => goToProfile()}
                                        onCancel={() => setError("")}
                                        confirmBtnBsStyle="danger"
                                        btnSize=""
                                        confirmBtnText="Close"
                                    >
                                        {error}
                                    </ReactBSAlert>
                                )}
                            </CardBody>
                            <CardFooter>
                                {!success &&
                                    <Button
                                        className="btn-round"
                                        color="info"
                                        onClick={(e) => resetPassword(e)}
                                        disabled={!newPassword.password || (newPassword.password !== newPassword.passwordConfirm)}
                                    >
                                        Change password
                                    </Button>
                                }
                                {success &&
                                    <Button
                                        className="btn-round"
                                        color="info"
                                        onClick={() => goToProfile()}
                                    >
                                        Go to Profile
                                    </Button>
                                }
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    </>
    );

}

export default ProfileResetPassword;
