import NavigationExamApi from '../../../../api/NavigationExamApi';
import ApiCaller from '../../../../lib/ApiCaller';
import NavigationExamListTable, {
  ListTable,
  ListTableView,
} from './navigationExamListTable';
import SyllabusApi from '../../../../api/SyllabusApi';

class NavigationExamListPresenter {
  loggedUser: any;
  navigationExamApi: NavigationExamApi;
  syllabusApi: SyllabusApi;
  table: ListTable;

  constructor(profile: any, private view: ListTableView, onGenerateCallback: (exam: any) => void) {
    this.loggedUser = profile;
    this.table = new NavigationExamListTable(this.loggedUser, view, onGenerateCallback);
    this.navigationExamApi = new NavigationExamApi(
      new ApiCaller(this.loggedUser.token),
    );
    this.syllabusApi = new SyllabusApi(new ApiCaller());
  }

  async getAllExams(): Promise<any> {
    return await this.navigationExamApi.getAllActiveExams();
  }

  async getAllSyllabus(): Promise<any> {
    const syllabus: any = await this.syllabusApi.getAllSyllabus(
      this.loggedUser.token,
    );
    return syllabus ? syllabus : [];
  }

  getTableColumns() {
    return this.table.getColumns();
  }

  getTableName(): string {
    return this.table.getName();
  }
}

export default NavigationExamListPresenter;
