import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';

import ExamCenterApi from 'api/ExamCenterApi';
import ApiCaller from 'lib/ApiCaller';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';

import './style.css';

export type ExamCenterFilterType = {
  title: string;
  examCentreId: string | undefined;
};

type FilterProps = {
  onSetFilter: (filter: any) => void;
};

const ExamCenterFilter: React.FC<FilterProps> = ({ onSetFilter }) => {
  const defaultFilter = {
    title: 'All exam centres',
    examCentreId: undefined,
  };
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const [examCentreFilter, setExamCentreFilter] = useState<
    ExamCenterFilterType | undefined
  >(defaultFilter);
  const [examCentreFilterValues, setExamCentreFilterValues] = useState<
    ExamCenterFilterType[]
  >([]);
  const examCenterApi = new ExamCenterApi(new ApiCaller(loggedUser.token));

  useEffect(() => {
    examCenterApi
      .getAllExamCenters()
      .then((centres: any) => {
        const filterValues = centres.centers.map((centre: any) => {
          return {
            title: `(${centre.code}) ${centre.name}`,
            examCentreId: centre._id,
          };
        });
        setExamCentreFilterValues(filterValues);
      })
      .catch((error) => {
        throw new Error(error.message);
      });
  }, []);

  const setFilter = useCallback(
    (filter: ExamCenterFilterType | undefined) => {
      setExamCentreFilter(filter);
      if (filter?.examCentreId) {
        onSetFilter({
          examCentreId: filter?.examCentreId,
        });
      } else {
        onSetFilter({});
      }
    },
    [onSetFilter],
  );

  const dropdownItems = useMemo(() => {
    return Object.keys(examCentreFilterValues).map((f, index) => {
      const filter = examCentreFilterValues[f];
      return (
        <DropdownItem key={index} onClick={() => setFilter(filter)}>
          {filter.title}
        </DropdownItem>
      );
    });
  }, [examCentreFilterValues]);

  return (
    <>
      <UncontrolledDropdown className={`filter-button_exam`}>
        <DropdownToggle caret>{examCentreFilter?.title}</DropdownToggle>
        <DropdownMenu className='filter-dropdown_scrollable'>
          <DropdownItem onClick={() => setFilter(defaultFilter)}>
            {defaultFilter.title}
          </DropdownItem>
          {dropdownItems}
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};

export default ExamCenterFilter;
