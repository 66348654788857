import ApiCaller from '../lib/ApiCaller';
import ExamResource from '../lib/common/models/examResource';
import ExamResourceCollection from '../lib/common/models/examResourceCollection';

class ExamResourceApi {
  apiCaller: ApiCaller;

  constructor(apiCaller: ApiCaller) {
    this.apiCaller = apiCaller;
  }

  getAll(): Promise<ExamResourceCollection> {
    return this.apiCaller.call(`/v1/examResource/`, 'GET').then((data) => {
      const resources = data.resources.map((resource: any) => ExamResource.prototype.fromDataModel(resource));
      return new ExamResourceCollection(resources);
    }).catch((err) => {
      console.error(err);
      throw Error(err);
    });
  }

  createResource(examResource: any, token: string): Promise<ExamResource> {
    return this.apiCaller
      .call(`/v1/examResource/`, 'POST', examResource, token)
      .then((resource) => {
        return ExamResource.prototype.fromDataModel(resource);
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  updateResource(examResource: any, token: string): Promise<ExamResource> {
    return this.apiCaller
      .call(`/v1/examResource/${examResource.id}`, 'PUT', examResource, token)
      .then((resource) => {
        return ExamResource.prototype.fromDataModel(resource);
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  getResourceById(resourceId: string): Promise<ExamResource> {
    return this.apiCaller
      .call(`/v1/examResource/${resourceId}`, 'GET')
      .then((resource) => {
        return ExamResource.prototype.fromDataModel(resource);
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  removeResource(resourceId: string): Promise<void> {
    return this.apiCaller
      .call(`/v1/examResource/${resourceId}`, 'DELETE')
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }
}

export default ExamResourceApi;
