export enum NewsletterContactStatus {
    Implicit = "implicit",
    Explicit = "explicit",
    Pending = "pending_confirmation",
    Unsubscribed = "unsubscribed",
    OnHold = "temp_hold",
    NotSet = "not_set",
}

interface NewsletterContactInfo {
    id?: string;
    firstName: string;
    lastName?: string;
    email: string;
    jobTitle: string;
    company: string;
    status?: string;
    listIds?: Array<String>;
    createDate?: Date;
    subscribeDate?: Date;
    timestamp?: number;
    verificationCode?: string;
}

class NewsletterContact {
    id?: string;
    firstName: string;
    lastName?: string;
    email: string;
    jobTitle: string;
    company: string;
    status: string;
    listIds?: Array<String>;
    createDate?: Date;
    subscribeDate?: Date;
    timestamp?: number;
    verificationCode?: string

    constructor(newsletterContactInfo: NewsletterContactInfo) {
        this.id = newsletterContactInfo.id;
        this.firstName = newsletterContactInfo.firstName;
        this.lastName = newsletterContactInfo.lastName;
        this.email = newsletterContactInfo.email;
        this.jobTitle = newsletterContactInfo.jobTitle;
        this.company = newsletterContactInfo.company;
        this.status = newsletterContactInfo.status || 'pending_confirmation';
        this.listIds = newsletterContactInfo.listIds;
        this.createDate = newsletterContactInfo.createDate;
        this.subscribeDate = newsletterContactInfo.subscribeDate;
        this.timestamp = newsletterContactInfo.timestamp;
        this.verificationCode = newsletterContactInfo.verificationCode;
    }

    toObject(): NewsletterContactInfo {
        return {
            id: this.id,
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            jobTitle: this.jobTitle,
            company: this.company,
            status: this.status,
            listIds: this.listIds,
            createDate: this.createDate,
            subscribeDate: this.subscribeDate,
            timestamp: this.timestamp,
            verificationCode: this.verificationCode
        }
    }
}

export default NewsletterContact;
