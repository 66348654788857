import UserRole from "./userRole";
import Question, {QuestionStatusLabels} from "./question";

export enum UserTypesLabels {
  admin = "admin",
  superUser = "superUser",
  questionWriter = "questionWriter",
  questionModerator = "questionModerator",
  mcaExaminer = "mcaExaminer",
  IAMIExamAdmin = "IAMIExamAdmin",
  IAMIExamManager = "IAMIExamManager",
  examCentre = "examCentre",
  mcaMarker = "mcaMarker",
  treasurer = "treasurer",
  auditor = "auditor",
  examCentreAuditor = "examCentreAuditor"
}

export const UserTypes = {
  [UserTypesLabels.admin]: "Administrator",
  [UserTypesLabels.superUser]: "Super User",
  [UserTypesLabels.questionWriter]: "Question Writer",
  [UserTypesLabels.questionModerator]: "Question Moderator",
  [UserTypesLabels.mcaExaminer]: "MCA Examiner",
  [UserTypesLabels.IAMIExamAdmin]: "IAMI Exam Admin",
  [UserTypesLabels.IAMIExamManager]: "IAMI Exam Manager",
  [UserTypesLabels.examCentre]: "Exam Centre",
  [UserTypesLabels.mcaMarker]: "IAMI Exam Marker",
  [UserTypesLabels.treasurer]: "IAMI Treasurer",
  [UserTypesLabels.auditor]: "IAMI Auditor",
  [UserTypesLabels.examCentreAuditor]: "Exam Centre Auditor"
}

export enum UserStatusLabels {
  pending = "pending",
  confirmed = "confirmed",
  inactive = "inactive"
}

export const UserStatus = {
  [UserStatusLabels.pending]: "Pending",
  [UserStatusLabels.confirmed]: "Confirmed",
  [UserStatusLabels.inactive]: "Inactive"
}

export interface UserInfo {
  name: string,
  email: string,
  token: string,
  type: string,
  status?: string,
  stripeId?: string,
  entityId?: any,
  parentId?: any,
  _id?: string,
}

export interface IProfile {
  _id: string,
  type: string,
  entityId?: any,
  roles?: UserRole
}

export interface IUserNotify {
  _id?: string,
  email: string,
  emailNotifications: boolean
}

export interface IUserDashboard {
  numerical: [
    { label: string, value: string, timestamp: number }
  ],
  chart: [
    { title: string, footer: string, value: number, badge: number, data: { label: string, x: string[], y: string[]}}
  ],
  gauge: [
    { title: string, subtitle: string, label: string, value: number }
  ],
  table: [
    { title: string, subtitle: string, rows: [ { icon: string, location: string, latLng: [number, number], values: string[] }]}
  ]
}

class User {
  _id?: string;
  name: string;
  email: string;
  token: string;
  type: string;
  status: string;
  stripeId: string;
  entityId: any;
  parentId: any;

  constructor(userInfo: UserInfo) {
    this.name = userInfo.name;
    this.email = userInfo.email;
    this.token = userInfo.token;
    this.type = userInfo.type;
    this.status = userInfo.status ? userInfo.status : '';
    this.stripeId = userInfo.stripeId ? userInfo.stripeId : '';
    this._id = userInfo._id ? userInfo._id : '';
    this.entityId = userInfo.entityId ? userInfo.entityId : undefined;
    this.parentId = userInfo.parentId ? userInfo.parentId : undefined;
  }

  load(data: any): User {
    return new User(data);
  }

  toObject(): any {
    return {
      name: this.name,
      email: this.email,
      type: this.type,
      stripeId: this.stripeId,
      status: this.status,
      entityId: this.entityId,
      parentId: this.parentId,
      _id: this._id,
    };
  }

  isEmpty(): boolean {
    return this.email === "";
  }

  isAdmin(): boolean {
    return this.isType(UserTypesLabels.admin);
  }

  isType(type:string): boolean {
    return this.type.toLowerCase() === type.toLowerCase();
  }

  hasAdminRole(): boolean {
    return this.isType(UserTypesLabels.IAMIExamAdmin) || this.isType(UserTypesLabels.IAMIExamManager) || this.isAdmin()
  }
  hasMCARole(): boolean {
    return this.isType(UserTypesLabels.mcaExaminer)
  }

  hasWritePermission(question: Question): boolean {
    const permissionUserId= (!question.createdBy || false)? question.updatedBy : question.createdBy._id;

    return this.hasAdminRole() || this.isType(UserTypesLabels.questionModerator) || ((this.isType(UserTypesLabels.questionWriter) && this._id === permissionUserId) &&
        question.status !== QuestionStatusLabels.ACTIVE );
  }

  hasWritePermissionAnswer(question: Question): boolean {
    const permissionUserId= (!question.createdBy || false) ? question.updatedBy : question.createdBy._id;

    return ((this.hasAdminRole() || (this.isType(UserTypesLabels.questionWriter) && this._id === permissionUserId)) &&
      question.status !== QuestionStatusLabels.ACTIVE) || this.isAdmin();
  }

  needsModulePermission(): boolean {
    return this.isType(UserTypesLabels.questionWriter) || this.isType(UserTypesLabels.questionModerator)
  }

  needsSyllabusPermission(): boolean {
    return this.isType(UserTypesLabels.IAMIExamManager) || this.isType(UserTypesLabels.mcaExaminer);
  }

  hasApprovePermission(question: Question): boolean {
    return !this.isType(UserTypesLabels.questionWriter) || this._id === question.createdBy?._id;
  }

  hasExamRequestPermission(): boolean {
    return this.isAdmin() || this.isType(UserTypesLabels.examCentre) || this.isType(UserTypesLabels.mcaMarker)
  }

  isPaymentAdmin(): boolean {
    return this.isType(UserTypesLabels.treasurer) || this.hasAdminRole();
  }

  hasCandidatePermissions(): boolean {
    return this.isType(UserTypesLabels.examCentre) || this.isAdmin();
  }

  hasMedicalReportsPermissions(): boolean {
    return this.hasAdminRole() || this.isType(UserTypesLabels.mcaExaminer);
  }

  hasMedicalReportsStatusPermissions(): boolean {
    return this.hasAdminRole() || this.isType(UserTypesLabels.mcaExaminer) || this.isType(UserTypesLabels.examCentre);
  }

  hasMedicalReportsDownloadingPermissions(): boolean {
    return this.isAdmin() || this.isType(UserTypesLabels.IAMIExamAdmin) || this.isType(UserTypesLabels.mcaExaminer);
  }

  hasMedicalReportsApprovalPermissions(): boolean {
    return this.isAdmin() || this.isType(UserTypesLabels.mcaExaminer);
  }

  hasMedicalReportsUploadingPermissions(): boolean {
    return this.isAdmin() || this.isType(UserTypesLabels.examCentre);
  }

  isExamCentreAuditor(): boolean {
    return this.isType(UserTypesLabels.examCentreAuditor);
  }

  isExamCentre(): boolean {
    return this.isType(UserTypesLabels.examCentre);
  }

  hasArchiveQuestionPermission(): boolean {
    return this.isAdmin() || this.isType(UserTypesLabels.IAMIExamAdmin);
  }

  hasCloseExamPermission(): boolean {
    return this.isAdmin() || this.isType(UserTypesLabels.IAMIExamAdmin);
  }

  hasExamCenterAdminPermissions(): boolean {
    return this.isAdmin() || this.isType(UserTypesLabels.IAMIExamAdmin);
  }

  hasCreateUserPermissions(): boolean {
    return this.isAdmin() || this.isType(UserTypesLabels.IAMIExamAdmin);
  }

  hasHistoricMarksPermission(): boolean {
    return this.hasAdminRole() || this.hasMCARole() || this.isExamCentreAuditor();
  }

  hasSecondaryPermissions(): boolean {
    return this.isType(UserTypesLabels.questionWriter) || this.isType(UserTypesLabels.questionModerator)
    || this.isType(UserTypesLabels.mcaMarker) || this.isType(UserTypesLabels.mcaExaminer)
  }
}

export default User;
