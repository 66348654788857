import React, { useState, useCallback, useEffect } from 'react';
import ReactDatetime from 'react-datetime';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Select from 'react-select';

import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  CardHeader,
  ModalHeader,
  ModalBody,
  Modal,
} from 'reactstrap';
import { DropdownButton, Dropdown } from 'react-bootstrap';

import Settings from '../../../../lib/settings';
import { AppState } from '../../../../store/store';

import ApiCaller from '../../../../lib/ApiCaller';
import NavigationExam, {
  ScriptIntentions,
  ScriptIntentionTypes,
} from '../../../../lib/common/models/navigationExam';
import ReactTable from '../../../../components/ReactTable/ReactTable';
import NavigationExamApi from '../../../../api/NavigationExamApi';
import { downloadDocument } from '../../../../shared/utils/ApiCommands';
import CandidateCollection from '../../../../lib/common/models/candidateCollection';
import Candidate from '../../../../lib/common/models/candidate';
import nzDocument from '../../../../lib/common/models/nzDocument';
import DetailLayout from 'layouts/DetailLayout';
import PaymentList from '../navigation_exam/navigationExamDetail/paymentList';
import CandidateEnrolmentDetail from '../navigation_exam/navigationExamDetail/candidateEnrollmentDetail';
import DocumentsApi from '../../../../api/DocumentsApi';

const ClosedExamDetail: React.FC = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const [currentExam, setCurrentExam] = useState<NavigationExam>();
  const [examCandidates, setExamCandidates] = useState<CandidateCollection>(
    new CandidateCollection([]),
  );
  const [modalDetail, setModalDetail] = useState<boolean>(false);
  const [docs, setDocs] = useState<Array<nzDocument>>([]);
  const [selectedCandidate, setSelectedCandidate] = useState<Candidate>();

  useEffect(() => {
    const exam = Settings.getCurrentNavigationExam();
    setCurrentExam(exam);

    if (exam.id) {
      const examApi = new NavigationExamApi(new ApiCaller(loggedUser.token));
      examApi.getCandidates(exam?.id).then((data: any) => {
        setExamCandidates(data);
      });
      const documentsApi = new DocumentsApi(new ApiCaller());
      documentsApi
        .getAllByProjectId(exam.id)
        .then((item: any) => {
          setDocs(item.files);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, []);

  const openDetails = useCallback(
    (e: any) => {
      const candidate = e.row.original;
      setSelectedCandidate(candidate);
      setModalDetail(true);
    },
    [setSelectedCandidate, setModalDetail],
  );

  const candidateColumns = [
    {
      Header: 'SERIAL NO.',
      accessor: (d: any) => (d.serialNumber ? d.serialNumber : '-'),
      sortable: true,
    },
    { Header: 'NAME', accessor: (d: any) => d.fullName(), sortable: true },
    {
      Header: 'BIRTH DATE',
      accessor: (d: any) => moment(d.birthDate).utc().format('DD-MM-YYYY'),
    },
    { Header: 'PASSPORT NO.', accessor: 'passportNumber', sortable: true },
    { Header: 'EMAIL', accessor: 'email', sortable: true },
    {
      Header: 'LEARNING DIFF.',
      accessor: (d: any) => (d.special ? 'Yes' : 'No'),
    },
    {
      Header: 'ACTIONS',
      Cell: (row: any) => {
        const [candidateScript] = docs.filter(
          (d) => d.type === row.row.original._id,
        );
        return (
          <>
            <DropdownButton variant="default" title="Actions" size="sm">
              <Dropdown.Item onClick={() => openDetails(row)}>
                <i className="nc-icon nc-settings" />
                &nbsp;&nbsp;DETAILS
              </Dropdown.Item>
              {currentExam?.scriptIntention === ScriptIntentionTypes.SCAN &&
                !loggedUser.isExamCentreAuditor() && (
                  <Dropdown.Item
                    onClick={() => {
                      downloadDocument(
                        candidateScript.fileHash,
                        candidateScript.displayName,
                        loggedUser.token,
                      );
                    }}
                    disabled={!candidateScript}>
                    <i className="nc-icon nc-cloud-download-93" />
                    &nbsp;&nbsp;DOWNLOAD SCRIPT
                  </Dropdown.Item>
                )}
            </DropdownButton>
          </>
        );
      },
    },
  ];

  return (
    <>
      <div className="content">
        <DetailLayout title={'Go back to Closed Exams'}>
          {currentExam && (
            <>
              <Card>
                <CardHeader>
                  <h4 className="card-title">{currentExam?.getExamNumber()}</h4>
                </CardHeader>
                <CardBody>
                  <Form className="form-horizontal">
                    <Row>
                      <Label sm="2">Exam Centre</Label>
                      <Col sm="10">
                        <FormGroup>
                          <Input
                            readOnly
                            value={currentExam.examCenterId?.name}
                            type="text"
                            name="Exam Centre"
                            placeholder="Exam Centre"
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">Syllabus</Label>
                      <Col sm="10">
                        <FormGroup>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="syllabus"
                            value={currentExam?.syllabus}
                            getOptionLabel={(item) =>
                              item.code + ' ' + item.title
                            }
                            placeholder="Choose a syllabus"
                            isDisabled={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">Module</Label>
                      <Col sm="10">
                        <FormGroup>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="module"
                            value={currentExam?.module}
                            getOptionLabel={(item) =>
                              item.code + ' ' + item.title
                            }
                            placeholder="Choose a module"
                            isDisabled={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">Date & Time</Label>
                      <Col sm="10">
                        <FormGroup>
                          <ReactDatetime
                            inputProps={{ disabled: true }}
                            timeFormat={'HH:mm (Z)'}
                            dateFormat={'DD-MM-YYYY'}
                            value={moment(currentExam?.date)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">Estimated Candidates</Label>
                      <Col sm="10">
                        <FormGroup>
                          <Input
                            value={currentExam.numCandidates}
                            type="number"
                            name="numCandidates"
                            placeholder="Estimated Candidates"
                            required
                            readOnly={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">Script Intention</Label>
                      <Col sm="10">
                        <FormGroup>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="status"
                            value={{
                              label:
                                ScriptIntentions[currentExam?.scriptIntention],
                              value: currentExam?.scriptIntention,
                            }}
                            options={Object.keys(ScriptIntentions).map(
                              function (type) {
                                return {
                                  value: type,
                                  label: ScriptIntentions[type],
                                };
                              },
                            )}
                            placeholder="Status"
                            isDisabled={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    {currentExam?.isCancelled() && (
                      <Row>
                        <Label sm="2">Cancellation date</Label>
                        <Col sm="10">
                          <FormGroup>
                            <ReactDatetime
                              inputProps={{ disabled: true }}
                              timeFormat={'HH:mm'}
                              dateFormat={'DD-MM-YYYY'}
                              value={moment(currentExam?.cancellationDate)}
                            />
                          </FormGroup>
                          {currentExam?.isLateCancellation() && (
                            <FormGroup className={`has-label has-danger`}>
                              <label className="error">
                                This exam was a late cancellation.
                              </label>
                            </FormGroup>
                          )}
                        </Col>
                      </Row>
                    )}
                    {loggedUser.hasAdminRole() && (
                      <Row>
                        <Label sm="2">First Marker</Label>
                        <Col sm="10">
                          <FormGroup>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="firstMarker"
                              value={currentExam?.firstMarker}
                              getOptionLabel={(item) =>
                                `${item.name} (${item.email})`
                              }
                              placeholder="Choose first marker"
                              isDisabled={true}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                    {loggedUser.hasAdminRole() && (
                      <Row>
                        <Label sm="2">Second Marker</Label>
                        <Col sm="10">
                          <FormGroup>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="secondMarker"
                              value={currentExam?.secondMarker}
                              getOptionLabel={(item) =>
                                `${item.name} (${item.email})`
                              }
                              placeholder="Choose second marker"
                              isDisabled={true}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                  </Form>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h4 className="card-title">Assigned Candidates</h4>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={examCandidates?.candidates}
                    columns={candidateColumns}
                  />
                </CardBody>
              </Card>
              <PaymentList refresh={examCandidates} />
            </>
          )}
        </DetailLayout>
      </div>

      <Modal isOpen={modalDetail}>
        <ModalHeader>Enrollment Detail</ModalHeader>
        <ModalBody>
          <CandidateEnrolmentDetail
            candidateId={selectedCandidate}
            examId={currentExam}
            setModal={setModalDetail}
            readOnly
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default ClosedExamDetail;
