import Candidate from "./candidate";
import Syllabus from "./syllabus";
import Module from "./module";
import ExamCenter from "./examCenter";
import Exam from "./exam";
import NavigationExam from "./navigationExam";

export enum CertificateStatusLabels {
    DRAFT = "DRAFT",
    VALID = "VALID", // Set to legacy status after maintenance
    ACTIVE = "ACTIVE",
    REVOKED = "REVOKED",
    ERROR = "ERROR"
}

export interface ICertificatePaymentSummary {
    center: {
        name: string,
        address: string
    },
    certificate: {
        displayName: string,
    }
    items: any[],
    subtotal: number,
    timestamp: string,
    invoiceDisplayName: string,
  }

interface ICertificateDetails {
    [key: string]: { result: string, grade?: number }
}

interface ExamCertificateInfo {
    _id?: string,
    sequence?: number,
    displayName?: string,
    identifier?: string,
    template?: string,
    title?: string,
    candidateId: any,
    date: string,
    syllabus: any,
    module: any,
    examCentre: any,
    examId: any,
    result: string,
    grade?: number,
    details?: ICertificateDetails,
    fileHash?: string,
    fileUrl?: string,
    status?: string,
    createdAt?: string
}

class ExamCertificate {
    _id?: string;
    sequence?: number;
    displayName?: string;
    identifier?: string;
    template?: string;
    title?: string;
    candidateId: any;
    date: string;
    syllabus: any;
    module: any;
    examCentre: any;
    examId: any;
    result: string;
    grade?: number;
    details?: ICertificateDetails;
    fileHash?: string;
    fileUrl?: string;
    status?: string;
    createdAt?: string;

    constructor(examCertificateInfo: ExamCertificateInfo) {
        this._id = examCertificateInfo._id
        this.sequence = examCertificateInfo.sequence
        this.displayName = examCertificateInfo.displayName
        this.identifier = examCertificateInfo.identifier
        this.template = examCertificateInfo.template
        this.title = examCertificateInfo.title
        this.candidateId = examCertificateInfo.candidateId;
        this.date = examCertificateInfo.date;
        this.syllabus = examCertificateInfo.syllabus;
        this.module = examCertificateInfo.module;
        this.examCentre = examCertificateInfo.examCentre;
        this.examId = examCertificateInfo.examId;
        this.result = examCertificateInfo.result;
        this.grade = examCertificateInfo.grade;
        this.details = examCertificateInfo.details;
        this.fileHash = examCertificateInfo.fileHash;
        this.fileUrl = examCertificateInfo.fileUrl;
        this.status = examCertificateInfo.status;
        this.createdAt = examCertificateInfo.createdAt;
    }

    load(data: any): ExamCertificate {
        return new ExamCertificate(data);
    }

    toObject(): any {
        return {
            _id: this._id,
            sequence: this.sequence,
            displayName: this.displayName,
            identifier: this.identifier,
            template: this.template,
            title: this.title,
            candidateId: this.candidateId,
            date: this.date,
            syllabus: this.syllabus,
            module: this.module,
            examCentre: this.examCentre,
            examId: this.examId,
            result: this.result,
            grade: this.grade,
            details: this.details,
            fileHash: this.fileHash,
            fileUrl: this.fileUrl,
            status: this.status,
            createdAt: this.createdAt
        }
    }

    getCertificateNumber(): string {
        if (this.displayName){
            return this.displayName
        }
        const sequence = this.sequence ? String(this.sequence).padStart(5, '0') : 'DRAFT';
        return `${this.syllabus?.title}-${new Date(this.date).getFullYear()}-${sequence}`;
    }

    getTitle(): string {
        return `${this.syllabus?.code}-${this.module?.code}: ${this.module?.title}`
    }

    fromDataModel(data: any): ExamCertificate {
        data.candidateId = data.candidateId ? new Candidate(data.candidateId) : null;
        data.syllabus = data.syllabus ? Syllabus.prototype.toDto(data.syllabus): null;
        data.module = data.module ? Module.prototype.toDto(data.module): null;
        data.examCentre = data.examCentre ? new ExamCenter(data.examCentre) : null;
        data.examId = data.examId ? new NavigationExam(data.examId) : null;
        return new ExamCertificate(data);
    }
}

export default ExamCertificate;
