import React, {useCallback} from 'react';
import { useDispatch } from 'react-redux';

import { DropdownButton, Dropdown } from 'react-bootstrap';
import {
  sortDateTable,
  DEFAULT_TIMEZONE_FORMAT,
  formatDateTime,
} from '../../../../shared/utils/dateTime';
import Settings from '../../../../lib/settings';
import ApiCaller from '../../../../lib/ApiCaller';
import { downloadMarkerReportByExamId } from '../../../../shared/utils/ApiCommands';
import {
  NavigationExamStatus,
  ScriptIntentions,
} from '../../../../lib/common/models/navigationExam';
import MarkerApi from '../../../../api/MarkerApi';
import NavigationExamApi from '../../../../api/NavigationExamApi';

export interface ListTableView {
  history: any;
  setStatus: any;
  layout: string;
}

export interface ListTable {
  getColumns(): any;
  getName(): string;
}

class CompletedExamListTable implements ListTable {
  loggedUser: any;
  view: ListTableView;
  navigationExamApi: NavigationExamApi;
  markerApi: MarkerApi;
  dispatch: any;

  constructor(profile: any, view: ListTableView) {
    this.loggedUser = profile;
    this.view = view;
    this.navigationExamApi = new NavigationExamApi(
      new ApiCaller(profile.token),
    );
    this.markerApi = new MarkerApi(new ApiCaller(profile.token));
    this.dispatch = useDispatch();
  }

  chooseExam(cell: any) {
    const exam = cell.row.original;
    Settings.setCurrentNavigationExam(exam);
    this.view.history.push(`/${this.view.layout}/navigation_exam/results`);
  }

  getName(): string {
    return 'Results Declared';
  }

  downloadMarkerReport(row: any) {
    downloadMarkerReportByExamId(
      row.row.original.id,
      `MARKER_REPORT_${row.row.original.getExamNumber()}`,
      this.loggedUser.token,
    );
  }

  closeExam(cell: any): void {
    const exam = cell.row.original;
    this.navigationExamApi.closeExam(exam.id).then((msg: any) => {
      this.view.history.push(`/${this.view.layout}/closed_exam/list`);
    });
  }



  getColumns(): any {
    return [
      ...this.getCustomUserColumns(),
      {
        Header: 'ACTIONS',
        Cell: (row: any) => (
            <>
          <DropdownButton variant="default" title="Actions" size="sm">
            <Dropdown.Item onClick={() => this.chooseExam(row)}>
              <i className="nc-icon nc-settings" />
              &nbsp;&nbsp;VIEW
            </Dropdown.Item>
            <Dropdown.Item onClick={() => this.downloadMarkerReport(row)}>
              <i className="nc-icon nc-cloud-download-93" />
              &nbsp;&nbsp;DOWNLOAD MARKER REPORT
            </Dropdown.Item>
            {this.loggedUser.hasCloseExamPermission() && (
              <Dropdown.Item onClick={() => this.closeExam(row)}>
                <i className="nc-icon nc-simple-remove" />
                &nbsp;&nbsp;CLOSE EXAM
              </Dropdown.Item>
            )}
          </DropdownButton>

            </>
        ),
      },
    ];
  }

  getCustomUserColumns() {
    const cols = [
      {
        Header: 'EXAM NUMBER',
        accessor: (d: any) => (d ? d.getExamNumber() : '-'),
        sortable: true,
      },
      {
        Header: 'EXAM CENTRE',
        accessor: (d: any) =>
          d.examCenterId
            ? `(${d.examCenterId.code}) ${d.examCenterId.name}`
            : '',
        sortable: true,
      },
      {
        Header: 'DATE',
        accessor: (d: any) => formatDateTime(d.date, DEFAULT_TIMEZONE_FORMAT),
        sortable: true,
        sortType: (a: any, b: any) => sortDateTable(a, b, 'date'),
      },
      {
        Header: 'SCRIPT INTENTION',
        accessor: (d: any) =>
          d.scriptIntention ? ScriptIntentions[d.scriptIntention] : '',
        sortable: true,
      },
      {
        Header: 'STATUS',
        accessor: (d: any) => (d.status ? NavigationExamStatus[d.status] : ''),
        sortable: true,
      },
    ];
    return cols;
  }
}

export default CompletedExamListTable;
