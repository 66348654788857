import { combineReducers, createStore } from 'redux';
import { Store } from 'antd/lib/form/interface';

import { sessionReducer } from './reducers/sessionReducer';
import { notificationReducer } from './notificationStore';
import { docsReducer } from './reducers/docsReducer';
import { markerReducer } from './reducers/markerReducer';
import { listsReducer } from './reducers/listsReducer';

const appReducer = combineReducers({
  session: sessionReducer,
  notification: notificationReducer,
  current: docsReducer,
  marker: markerReducer,
  lists: listsReducer,
});

export type AppState = ReturnType<typeof appReducer>;

export default function configureStore(): Store {
  const store = createStore(appReducer);
  return store;
}
