import React, { useState, useEffect } from 'react';
import ReactTable from 'components/ReactTable/ReactTable';
import FilteredComponent from '../filteredComponent';
import { DateFilteredTableProps } from '../types';
import AvCandidates from './AvCandidates';
import ExamCenterFilter from '../filters/examCenterFilter';
import {Button, Col, Row} from 'reactstrap';
import {onTableExport} from "../../../../shared/utils/excel";
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';


const ExamCenterDateFilteredTable: React.FC<DateFilteredTableProps> = ({
  columns,
  fetchMethod,
  multipleTables,
  tableName = "Data",
  initialGroupBy = [],
  hasTotal = false,
}) => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const [tableData, setTableData] = useState<any>([]);
  const [filterParams, setFilterParams] = useState<any>({});
  
  return (
    <>
      <div className="filtered-table__container">
        <Row>
          {!loggedUser.isExamCentre() && (
            <Col>
              <ExamCenterFilter onSetFilter={setFilterParams} />
            </Col>
          )}
          <Col className="text-right">
            <Button
              onClick={() => onTableExport(tableName, columns, tableData)}
              color="success"
              className="btn-label">
              <span>
                <i className="nc-icon nc-cloud-download-93" />
              </span>
              Export to Excel
            </Button>
          </Col>
        </Row>
        <FilteredComponent
          component={[
            multipleTables && <AvCandidates data={tableData} />,
            <ReactTable
              data={tableData}
              columns={columns}
              topPaginationClassName="table-pagination_top"
              initialGroupBy={initialGroupBy}
              hasTotal={hasTotal}
            />,
          ]}
          fetchMethod={fetchMethod}
          updateDataMethod={setTableData}
          componentName="table"
          examCenterFilter
          params={filterParams}
        />
      </div>
    </>
  );
};

export default ExamCenterDateFilteredTable;
