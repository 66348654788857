import React from 'react';
import {useSelector} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {DropdownButton, Dropdown} from 'react-bootstrap';
import Select from 'react-select';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
  CardTitle,
  FormGroup,
} from 'reactstrap';

import Settings from '../../../../lib/settings';
import ApiCaller from '../../../../lib/ApiCaller';
import ReactTable from '../../../../components/ReactTable/ReactTable';
import {AppState} from '../../../../store/store';
import ModuleApi from 'api/ModuleApi';
import SyllabusApi from 'api/SyllabusApi';
import SyllabusCollection from 'lib/common/models/syllabusCollection';
import ModuleCollection from 'lib/common/models/moduleCollection';

const ModuleList: React.FC = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const layout = useSelector((state: AppState) => state.session.layout);
  const [syllabus, updateSyllabus] = React.useState<SyllabusCollection>(
    new SyllabusCollection([]),
  );
  const [syllabusId, setSyllabusId] = React.useState('');
  const [modules, updateModules] = React.useState<ModuleCollection>(
    new ModuleCollection([]),
  );
  const history = useHistory();

  React.useEffect(() => {
    const syllabusApi = new SyllabusApi(new ApiCaller());
    syllabusApi.getAllSyllabus(loggedUser.token).then((syllabus: any) => {
      if (syllabus && Object.keys(syllabus).length > 0) {
        updateSyllabus(syllabus);
      }
    });
  }, []);

  const onSyllabusChange = (selectedSyllabus: any) => {
    setSyllabusId(selectedSyllabus._id);
  };

  React.useEffect(() => {
    if (syllabusId !== '') {
      const moduleApi = new ModuleApi(new ApiCaller(loggedUser.token));
      moduleApi.getAllModules(syllabusId).then((module: any) => {
        if (module && Object.keys(module).length > 0) {
          updateModules(module);
        }
      });
    }
  }, [syllabusId]);

  const chooseModule: any = (cell: any) => {
    const module = cell.row.original;
    Settings.setCurrentModule(module);
    history.push(`/${layout}/module/detail`);
  };

  const deleteModule: any = (cell: any) => {
    const module = cell.row.original;
    const moduleApi = new ModuleApi(new ApiCaller());
    moduleApi
      .deleteModule(module._id || '', loggedUser.token)
      .then(() => {
        toast.success('Module deleted');
        const updatedCollection = new ModuleCollection(
          modules.modules.filter((item: any) => item !== module),
        );
        updateModules(updatedCollection);
      })
      .catch((err) => {
        console.error(err);
        toast.error('Error deleting syllabus');
      });
  };

  const columns = [
    {Header: 'CODE', accessor: 'code', sortable: true},
    {Header: 'TITLE', accessor: 'title', sortable: true},
    {
      Header: 'ACTIONS',
      Cell: (row: any) => (
        <DropdownButton variant="default" title="Actions" size="sm">
          <Dropdown.Item onClick={() => chooseModule(row)}>
            <i className="nc-icon nc-settings" />
            &nbsp;&nbsp;VIEW
          </Dropdown.Item>
          <Dropdown.Item onClick={() => deleteModule(row)}>
            <i className="nc-icon nc-simple-delete" />
            &nbsp;&nbsp;DELETE
          </Dropdown.Item>
        </DropdownButton>
      ),
    },
  ];

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Module List</CardTitle>
              </CardHeader>
              <Col sm="10">
                <Row>
                  <Col>
                    <Link to={`/${layout}/module/create`} className="btn-label">
                      <Button color="info">
                        <span>
                          <i className="nc-icon nc-simple-add" />
                        </span>
                        Create
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Col>
              <Col sm="10">
                <FormGroup>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="syllabus"
                    onChange={onSyllabusChange}
                    options={syllabus.syllabuses}
                    getOptionLabel={(item) => item.code + ' ' + item.title}
                    getOptionValue={(item) => item.code}
                    placeholder="Choose a syllabus"
                  />
                </FormGroup>
              </Col>
              <CardBody>
                <ReactTable data={modules.modules} columns={columns} />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </div>
    </>
  );
};

export default ModuleList;
