import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { History } from 'history';

import Settings from 'lib/settings';
import { updateJwt, updateSession } from 'store/actions/session/sessionActions';
import { updateNotifications } from 'store/notificationStore';

import User from '../lib/common/models/user';
import defaultAvatar from '../assets/img/placeholder.jpg';
import { AppState } from '../store/store';
import { getJwtExpiration } from '../shared/utils/jwt';
import AuthApi from '../api/AuthApi';
import ApiCaller from '../lib/ApiCaller';

interface AuthVerifierProps extends RouteComponentProps<any> {
  history: History;
}

const AuthVerifier: React.FC<AuthVerifierProps> = ({ history }) => {
  const dispatch = useDispatch();
  const user = Settings.getCurrentUser();

  const jwt = useSelector((state: AppState) => state.session.userInfo.token);
  const jwtExpiry = useSelector(
    (state: AppState) => state.session.tokenExpiration,
  );
  const [lastActive, setLastActive] = useState(Date.now());

  useEffect(() => {
    const setLastActiveNow = () => setLastActive(Date.now());
    window.addEventListener('keydown', setLastActiveNow);
    window.addEventListener('scroll', setLastActiveNow);
    window.addEventListener('click', setLastActiveNow);

    // Clean up the event listeners when the component is unmounted
    return () => {
      window.removeEventListener('keydown', setLastActiveNow);
      window.removeEventListener('scroll', setLastActiveNow);
      window.removeEventListener('click', setLastActiveNow);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(async () => {
      const currentTimestamp = Math.floor(Date.now() / 1000); // In seconds
      const fiveMinutes = 5 * 60;
      const thirtyMinutes = 5 * 60;

      if (
        currentTimestamp > (jwtExpiry ?? 0) - fiveMinutes &&
        Date.now() < lastActive + thirtyMinutes * 1000
      ) {
        try {
          const newJwt = await refreshToken(jwt);
          const newJwtExpiry = getJwtExpiration(newJwt);

          dispatch(updateJwt(newJwt, newJwtExpiry));
        } catch (err) {
          console.error('Failed to refresh JWT', err);
        }
      }
    }, 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [jwt, jwtExpiry, lastActive, dispatch]);

  const refreshToken = (jwt: string): Promise<string> => {
    const authApi = new AuthApi(new ApiCaller(jwt));
    return authApi
      .refresh()
      .then((user: User | void) => {
        return user ? user?.token : '';
      })
      .catch((err) => {
        console.error('Error refreshing JWT token');
        return jwt;
      });
  };

  const logOutUser = () => {
    Settings.logout();
    dispatch(
      updateSession({
        loggedIn: false,
        userInfo: new User({
          name: 'Guest',
          email: '',
          token: 'GUEST_TOKEN',
          type: 'guest',
        }),
        tokenExpiration: 0,
        ready: false,
        type: 'guest',
        avatar: defaultAvatar,
        layout: 'navozyme',
      }),
    );
    dispatch(updateNotifications({ options: [] }));
    history.push('/login');
  };

  const parseJwt = (token: string) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };

  const isTokenExpired = useCallback(() => {
    const userToken = Settings.getCurrentUser().token;
    if (userToken) {
      const decodedJwt = parseJwt(userToken);
      if (decodedJwt?.exp * 1000 < Date.now()) {
        return true;
      }
    }
    return false;
  }, []);

  useEffect(() => {
    if (history.location.pathname !== '/login') {
      if (isTokenExpired()) {
        logOutUser();
      }
    }
    history.listen(() => {
      if (isTokenExpired()) {
        logOutUser();
      }
    });
  }, [history, isTokenExpired]);

  useEffect(() => {
    if (
      !user.token &&
      history.location.pathname !== '/login' &&
      !history.location.pathname.startsWith('/recovery') &&
      !history.location.pathname.startsWith('/auth/verifyCandidate')
    ) {
      history.push('/login');
    }
  }, []);

  return <div></div>;
};

export default withRouter<AuthVerifierProps, any>(AuthVerifier);
