import React, { useEffect, useState } from "react";

import { Row, Col } from "reactstrap";

import QuestionsPerModuleAndTopicReport from "views/components/dashboard/questions/QuestionsPerModuleAndTopicReport";

import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";
import { AppState } from "store/store";
import SyllabusApi from "api/SyllabusApi";
import ApiCaller from "lib/ApiCaller";
import Syllabus from '../../../lib/common/models/syllabus';

interface ContainerProps {
}

const QuestionModeratorDashboard: React.FC<ContainerProps> = ({ }) => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const [allowedSyllabuses, setAllowedSyllabuses] = useState<string[]>([]);
  
  useEffect(() => {
    const syllabusApi = new SyllabusApi(new ApiCaller());
    syllabusApi.getAllSyllabus(loggedUser.token).then((syllabusList: any) => {
      setAllowedSyllabuses(syllabusList.syllabuses.map((syllabus: Syllabus) => syllabus.code));
    });
  }, [loggedUser]);

  return (
    <div className="content">
      <Row>
        <Col>
          <QuestionsPerModuleAndTopicReport allowedSyllabuses={allowedSyllabuses} />
        </Col>
      </Row>
    </div>
  );
}

export default QuestionModeratorDashboard;
