import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';

import { useSelector } from 'react-redux';

import 'react-toastify/dist/ReactToastify.css';
import { AppState } from '../../../store/store';
import ExamCentresMarksReport from 'views/components/dashboard/marks/ExamCentresMarksReport';
import Syllabus from '../../../lib/common/models/syllabus';
import ApiCaller from 'lib/ApiCaller';
import SyllabusApi from 'api/SyllabusApi';
import QuestionBankReport from '../../components/dashboard/questions/QuestionBankReport';
import ExamAnalytics from '../admin/exam_analytics/ExamAnalytics';

interface ContainerProps {}

const ExamCentreDashboard: React.FC<ContainerProps> = ({}) => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const [allowedSyllabuses, setAllowedSyllabuses] = useState<string[]>([]);

  useEffect(() => {
    const syllabusApi = new SyllabusApi(new ApiCaller());
    syllabusApi.getAllSyllabus(loggedUser.token).then((syllabusList: any) => {
      setAllowedSyllabuses(
        syllabusList.syllabuses.map((syllabus: Syllabus) => syllabus.code),
      );
    });
  }, [loggedUser]);

  return (
    <>
      <div className="content">
        <Row>
          <Col>
            <ExamCentresMarksReport {...{ allowedSyllabuses }} />
          </Col>
        </Row>
        <Row>
          <Col>
            <ExamAnalytics />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ExamCentreDashboard;
