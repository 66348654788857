/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from 'react';
// react plugin used to create charts
import { Line, Bar, Doughnut } from 'react-chartjs-2';
// react plugin for creating vector maps
import WorldMap from './components/WorldMap';

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from 'reactstrap';

import { chartExample4 } from 'variables/charts.js';

import { useSelector } from 'react-redux';
import { AppState } from '../store/store';

import ApiCaller from '../lib/ApiCaller';
import UserApi from '../api/UserApi';

import { ToastContainer } from 'react-toastify';
import StatsCardsContainer from '../components/StatsCardsContainer/StatsCardsContainer';
import PassRateReport from './components/dashboard/exams/PassRateReport';
import ExamCandidatesReport from './components/dashboard/exams/ExamCandidatesReport';
import { DEFAULT_DASHBOARD_STATS } from '../shared/constants/defaultDashboardStats';

import 'react-toastify/dist/ReactToastify.css';
import SyllabusApi from 'api/SyllabusApi';
import Syllabus from '../lib/common/models/syllabus';

interface ContainerProps {}

const UserDashboard: React.FC<ContainerProps> = ({}) => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);

  const [data, setData] = useState(DEFAULT_DASHBOARD_STATS);
  const [allowedSyllabuses, setAllowedSyllabuses] = useState<string[]>([]);
  
  useEffect(() => {
    const syllabusApi = new SyllabusApi(new ApiCaller());
    syllabusApi.getAllSyllabus(loggedUser.token).then((syllabusList: any) => {
      setAllowedSyllabuses(syllabusList.syllabuses.map((syllabus: Syllabus) => syllabus.code));
    });
  }, [loggedUser]);

  useEffect(() => {
    if (!loggedUser?.isEmpty()) {
      const userApi = new UserApi(new ApiCaller());
      userApi.getMyStats(loggedUser.token).then((data) => {
        if (data) {
          setData(data);
        }
      });
    }
  }, [loggedUser]);

  const getGaugeData = (
    info: any,
    primaryColor: string,
    secondaryColor?: string,
  ): any => {
    return {
      labels: [1, 2],
      datasets: [
        {
          label: info?.label,
          pointRadius: 0,
          pointHoverRadius: 0,
          backgroundColor: [primaryColor, secondaryColor || '#f4f3ef'],
          borderWidth: 0,
          barPercentage: 1.6,
          data: [info?.value, 100 - info?.value],
        },
      ],
    };
  };

  const getGaugeOptions = (info: any): any => {
    const data = {
      elements: {
        center: {
          text: `${info.value}%`,
          color: '#66615c', // Default is #000000
          fontStyle: 'Arial', // Default is Arial
          sidePadding: 60, // Defualt is 20 (as a percentage)
        },
      },
      cutoutPercentage: 90,
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              display: false,
            },
            gridLines: {
              drawBorder: false,
              zeroLineColor: 'transparent',
              color: 'rgba(255,255,255,0.05)',
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              drawBorder: false,
              color: 'rgba(255,255,255,0.1)',
              zeroLineColor: 'transparent',
            },
            ticks: {
              display: false,
            },
          },
        ],
      },
    };

    return data;
  };

  const getChartData = (info: any, color: string): any => {
    const data = {
      labels: info.data.x,
      datasets: [
        {
          label: info.data.label,
          borderColor: color,
          pointRadius: 0,
          pointHoverRadius: 0,
          fill: false,
          borderWidth: 3,
          barPercentage: 1.6,
          data: info.data.y,
        },
      ],
    };

    return data;
  };

  const getChartOptions = (): any => {
    const data = {
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              fontColor: '#9f9f9f',
              beginAtZero: false,
              maxTicksLimit: 5,
            },
            gridLines: {
              drawBorder: false,
              zeroLineColor: 'transparent',
              color: 'rgba(255,255,255,0.05)',
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              drawBorder: false,
              color: 'rgba(255,255,255,0.1)',
              zeroLineColor: 'transparent',
              display: false,
            },
            ticks: {
              padding: 20,
              fontColor: '#9f9f9f',
            },
          },
        ],
      },
    };

    return data;
  };

  return (
    <>
      <div className="content">
        <Row>
          <StatsCardsContainer data={data.numerical} />
        </Row>
        <Row>
          {data.chart[0] && (
            <Col lg="4" sm="6">
              <Card>
                <CardHeader>
                  <Row>
                    <Col sm="7">
                      <div className="numbers pull-left">
                        {data.chart[0].value}
                      </div>
                    </Col>
                    <Col sm="5">
                      <div className="pull-right">
                        <Badge color="success" pill>
                          {`${data.chart[0].badge}%`}
                        </Badge>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <h6 className="big-title">{data.chart[0].title}</h6>
                  <Line
                    data={getChartData(data.chart[0], '#6bd098')}
                    options={getChartOptions()}
                    height={380}
                    width={826}
                  />
                </CardBody>
                <CardFooter>
                  <hr />
                  <Row>
                    <Col sm="7">
                      <div className="footer-title">{data.chart[0].footer}</div>
                    </Col>
                    <Col sm="5">
                      <div className="pull-right">
                        <Button
                          className="btn-round btn-icon"
                          color="success"
                          size="sm">
                          <i className="nc-icon nc-simple-add" />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </Col>
          )}
          {data.chart[1] && (
            <Col lg="4" sm="6">
              <Card>
                <CardHeader>
                  <Row>
                    <Col sm="7">
                      <div className="numbers pull-left">
                        {data.chart[1].value}
                      </div>
                    </Col>
                    <Col sm="5">
                      <div className="pull-right">
                        <Badge color="danger" pill>
                          {`${data.chart[1].badge}%`}
                        </Badge>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <h6 className="big-title">{data.chart[1].title}</h6>
                  <Line
                    data={getChartData(data.chart[1], '#ef8156')}
                    options={getChartOptions()}
                    height={380}
                    width={828}
                  />
                </CardBody>
                <CardFooter>
                  <hr />
                  <Row>
                    <Col sm="7">
                      <div className="footer-title">{data.chart[1].footer}</div>
                    </Col>
                    <Col sm="5">
                      <div className="pull-right">
                        <Button
                          className="btn-round btn-icon"
                          color="danger"
                          size="sm">
                          <i className="nc-icon nc-button-play" />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </Col>
          )}
          {data.chart[2] && (
            <Col lg="4" sm="6">
              <Card>
                <CardHeader>
                  <Row>
                    <Col sm="7">
                      <div className="numbers pull-left">
                        {data.chart[2].value}
                      </div>
                    </Col>
                    <Col sm="5">
                      <div className="pull-right">
                        <Badge color="warning" pill>
                          {`${data.chart[2].badge}%`}
                        </Badge>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <h6 className="big-title">{data.chart[2].title}</h6>
                  <Line
                    data={getChartData(data.chart[2], '#2CA8FF')}
                    options={getChartOptions()}
                    height={380}
                    width={826}
                  />
                </CardBody>
                <CardFooter>
                  <hr />
                  <Row>
                    <Col sm="7">
                      <div className="footer-title">{data.chart[2].footer}</div>
                    </Col>
                    <Col sm="5">
                      <div className="pull-right">
                        <Button
                          className="btn-round btn-icon"
                          color="warning"
                          size="sm">
                          <i className="nc-icon nc-alert-circle-i" />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </Col>
          )}
        </Row>
        {data.table[0] && data.table[0].rows && (
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">{data.table[0].title}</CardTitle>
                  <p className="card-category">{data.table[0].subtitle}</p>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="6">
                      <Table responsive>
                        <tbody>
                          {data.table[0].rows.map((row, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <div className="flag">
                                    <img
                                      alt="..."
                                      // src={require(`${row.icon}`)}
                                      src={require(`assets/img/flags/${row.icon}.png`)}
                                    />
                                  </div>
                                </td>
                                <td>{row.location}</td>
                                {row.values.map((value, index) => {
                                  return (
                                    <td key={index} className="text-right">
                                      {value}
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </Col>
                    <Col className="ml-auto mr-auto" md="6">
                      {data.table[0] && (
                        <WorldMap markers={data.table[0].rows} />
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
        <Row>
          <Col md="6">
            <Card className="card-tasks">
              <CardHeader>
                <CardTitle tag="h4">Last Question Revoke</CardTitle>
                <h5 className="card-category">
                  Reasons why a question has been revoked
                </h5>
              </CardHeader>
              <CardBody>
                <div className="table-full-width table-responsive">
                  <Table>
                    <tbody>
                      <tr>
                        <td>
                          <FormGroup check>
                            <Label check>
                              <Input defaultChecked type="checkbox" />
                              <span className="form-check-sign" />
                            </Label>
                          </FormGroup>
                        </td>
                        <td className="img-row">
                          <div className="img-wrapper">
                            <img
                              alt="..."
                              className="img-raised"
                              src={require('assets/img/faces/ayo-ogunseinde-2.jpg')}
                            />
                          </div>
                        </td>
                        <td className="text-left">
                          Sign contract for "What are conference organizers
                          afraid of?"
                        </td>
                        <td className="td-actions text-right">
                          <Button
                            className="btn-round btn-icon btn-icon-mini btn-neutral"
                            color="info"
                            id="tooltip42906017"
                            title=""
                            type="button">
                            <i className="nc-icon nc-ruler-pencil" />
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip42906017">
                            Edit Task
                          </UncontrolledTooltip>
                          <Button
                            className="btn-round btn-icon btn-icon-mini btn-neutral"
                            color="danger"
                            id="tooltip570363224"
                            title=""
                            type="button">
                            <i className="nc-icon nc-simple-remove" />
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip570363224">
                            Remove
                          </UncontrolledTooltip>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <FormGroup check>
                            <Label check>
                              <Input type="checkbox" />
                              <span className="form-check-sign" />
                            </Label>
                          </FormGroup>
                        </td>
                        <td className="img-row">
                          <div className="img-wrapper">
                            <img
                              alt="..."
                              className="img-raised"
                              src={require('assets/img/faces/erik-lucatero-2.jpg')}
                            />
                          </div>
                        </td>
                        <td className="text-left">
                          Lines From Great Russian Literature? Or E-mails From
                          My Boss?
                        </td>
                        <td className="td-actions text-right">
                          <Button
                            className="btn-round btn-icon btn-icon-mini btn-neutral"
                            color="info"
                            id="tooltip584875601"
                            title=""
                            type="button">
                            <i className="nc-icon nc-ruler-pencil" />
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip584875601">
                            Edit Task
                          </UncontrolledTooltip>
                          <Button
                            className="btn-round btn-icon btn-icon-mini btn-neutral"
                            color="danger"
                            id="tooltip517629613"
                            title=""
                            type="button">
                            <i className="nc-icon nc-simple-remove" />
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip517629613">
                            Remove
                          </UncontrolledTooltip>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <FormGroup check>
                            <Label check>
                              <Input defaultChecked type="checkbox" />
                              <span className="form-check-sign" />
                            </Label>
                          </FormGroup>
                        </td>
                        <td className="img-row">
                          <div className="img-wrapper">
                            <img
                              alt="..."
                              className="img-raised"
                              src={require('assets/img/faces/kaci-baum-2.jpg')}
                            />
                          </div>
                        </td>
                        <td className="text-left">
                          Using dummy content or fake information in the Web
                          design process can result in products with unrealistic
                        </td>
                        <td className="td-actions text-right">
                          <Button
                            className="btn-round btn-icon btn-icon-mini btn-neutral"
                            color="info"
                            id="tooltip792337830"
                            title=""
                            type="button">
                            <i className="nc-icon nc-ruler-pencil" />
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip792337830">
                            Edit Task
                          </UncontrolledTooltip>
                          <Button
                            className="btn-round btn-icon btn-icon-mini btn-neutral"
                            color="danger"
                            id="tooltip731952378"
                            title=""
                            type="button">
                            <i className="nc-icon nc-simple-remove" />
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip731952378">
                            Remove
                          </UncontrolledTooltip>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <FormGroup check>
                            <Label check>
                              <Input type="checkbox" />
                              <span className="form-check-sign" />
                            </Label>
                          </FormGroup>
                        </td>
                        <td className="img-row">
                          <div className="img-wrapper">
                            <img
                              alt="..."
                              className="img-raised"
                              src={require('assets/img/faces/joe-gardner-2.jpg')}
                            />
                          </div>
                        </td>
                        <td className="text-left">
                          But I must explain to you how all this mistaken idea
                          of denouncing pleasure
                        </td>
                        <td className="td-actions text-right">
                          <Button
                            className="btn-round btn-icon btn-icon-mini btn-neutral"
                            color="info"
                            id="tooltip825783733"
                            title=""
                            type="button">
                            <i className="nc-icon nc-ruler-pencil" />
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip825783733">
                            Edit Task
                          </UncontrolledTooltip>
                          <Button
                            className="btn-round btn-icon btn-icon-mini btn-neutral"
                            color="danger"
                            id="tooltip285089652"
                            title=""
                            type="button">
                            <i className="nc-icon nc-simple-remove" />
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip285089652">
                            Remove
                          </UncontrolledTooltip>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  <i className="fa fa-refresh spin" />
                  Updated 3 minutes ago
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col md="6">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">2021 Exam Center Sales</CardTitle>
                <p className="card-category">All products including Taxes</p>
              </CardHeader>
              <CardBody>
                <Bar
                  data={chartExample4.data}
                  options={chartExample4.options}
                />
              </CardBody>
              <CardFooter>
                <div className="legend">
                  <i className="fa fa-circle text-info" />
                  Tesla Model S <i className="fa fa-circle text-danger" />
                  BMW 5 Series
                </div>
                <hr />
                <div className="stats">
                  <i className="fa fa-check" />
                  Data information certified
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Row>
          {data.gauge[0] && (
            <Col md="3">
              <Card>
                <CardHeader>
                  <CardTitle>{data.gauge[0].title}</CardTitle>
                  <p className="card-category">{data.gauge[0].subtitle}</p>
                </CardHeader>
                <CardBody>
                  <Doughnut
                    data={getGaugeData(data.gauge[0], '#fcc468')}
                    options={getGaugeOptions(data.gauge[0])}
                    // className="ct-chart ct-perfect-fourth"
                    height={300}
                    width={456}
                  />
                </CardBody>
                <CardFooter>
                  <div className="legend">
                    <i className="fa fa-circle text-info" />
                    Open
                  </div>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-calendar" />
                    Number of emails sent
                  </div>
                </CardFooter>
              </Card>
            </Col>
          )}
          {data.gauge[1] && (
            <Col md="3">
              <Card>
                <CardHeader>
                  <CardTitle>{data.gauge[1].title}</CardTitle>
                  <p className="card-category">{data.gauge[1].subtitle}</p>
                </CardHeader>
                <CardBody>
                  <Doughnut
                    data={getGaugeData(data.gauge[1], '#f17e5d')}
                    options={getGaugeOptions(data.gauge[1])}
                    // className="ct-chart ct-perfect-fourth"
                    height={300}
                    width={456}
                  />
                </CardBody>
                <CardFooter>
                  <div className="legend">
                    <i className="fa fa-circle text-warning" />
                    Visited
                  </div>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-check" />
                    Campaign sent 2 days ago
                  </div>
                </CardFooter>
              </Card>
            </Col>
          )}
          {data.gauge[2] && (
            <Col md="3">
              <Card>
                <CardHeader>
                  <CardTitle>{data.gauge[2].title}</CardTitle>
                  <p className="card-category">{data.gauge[2].subtitle}</p>
                </CardHeader>
                <CardBody>
                  <Doughnut
                    data={getGaugeData(data.gauge[2], '#66615b')}
                    options={getGaugeOptions(data.gauge[2])}
                    // className="ct-chart ct-perfect-fourth"
                    height={300}
                    width={456}
                  />
                </CardBody>
                <CardFooter>
                  <div className="legend">
                    <i className="fa fa-circle text-danger" />
                    Completed
                  </div>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-clock-o" />
                    Updated 3 minutes ago
                  </div>
                </CardFooter>
              </Card>
            </Col>
          )}
          {data.gauge[3] && (
            <Col md="3">
              <Card>
                <CardHeader>
                  <CardTitle>{data.gauge[3].title}</CardTitle>
                  <p className="card-category">{data.gauge[3].subtitle}</p>
                </CardHeader>
                <CardBody>
                  <Doughnut
                    data={getGaugeData(data.gauge[3], '#4acccd')}
                    options={getGaugeOptions(data.gauge[3])}
                    // className="ct-chart ct-perfect-fourth"
                    height={300}
                    width={456}
                  />
                </CardBody>
                <CardFooter>
                  <div className="legend">
                    <i className="fa fa-circle text-secondary" />
                    Ended
                  </div>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-history" />
                    Total users
                  </div>
                </CardFooter>
              </Card>
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            <PassRateReport allowedSyllabuses={allowedSyllabuses} />
          </Col>
        </Row>
        <Row>
          <Col>
            <ExamCandidatesReport />
          </Col>
        </Row>
      </div>
      <ToastContainer />
    </>
  );
};

export default UserDashboard;
