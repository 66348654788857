import React, {ChangeEvent} from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import NewsletterContact from '../../../../lib/common/models/newsletterContact';
import AuthApi from '../../../../api/AuthApi';
import ApiCaller from "../../../../lib/ApiCaller";

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Label,
    FormGroup,
    Form,
    Input,
    InputGroupText,
    InputGroup,
    Container,
    Col,
    Row, CardTitle,
} from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";

const RegisterNewsletter: React.FC = () => {
    const [contactInfo, setContactInfo] = React.useState<NewsletterContact>(new NewsletterContact({
        firstName: '',
        lastName: '',
        email: '',
        jobTitle: '',
        company: ''
    }));

    const [conditionsState, setConditionsState] = React.useState<boolean>(false);

    const [formState, setFormState] = React.useState({
        firstNameState: "",
        emailState: "",
        companyState: ""
    })

    const [alertState, setAlertState] = React.useState<any>({
        alert: null
    });

    const onFieldChange = (e: ChangeEvent<HTMLInputElement>, stateName: string, type: string) => {

        let status = "has-success";
        const value = e.target.value;
        const data = contactInfo.toObject();

        switch (type) {
            case "length":
                if (value.length > 0) {
                    status = "has-success"
                } else {
                    status = "has-danger";
                }
                break;
            case "email":
                var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (emailRex.test(value)) {
                    status = "has-success"
                } else{
                    status = "has-danger";
                }
                break;
        }

        data[stateName] = value;
        setContactInfo(new NewsletterContact(data));

        setFormState({
            ...formState,
            [stateName + "State"]: status
        });
    }

    const hideAlert = () => {
        setAlertState({
            alert: null,
        });
    }
    const subscribe = (e: any) => {
        if (!conditionsState) {
            toast.error('Please accept terms and conditions');
        } else {
            var authApi = new AuthApi(new ApiCaller());
            authApi.subscribe(contactInfo).then(() => {
                setAlertState({
                    alert: (
                        <ReactBSAlert
                            success
                            style={{ display: "block", marginTop: "-100px" }}
                            title="Thanks for subscribing!"
                            onConfirm={() => hideAlert()}
                            onCancel={() => hideAlert()}
                            confirmBtnBsStyle="info"
                            btnSize=""
                        >
                            You will shortly recieve a confirmation email!
                        </ReactBSAlert>
                    )
            });
        })}
    }

    return (
        <>
            <div className="content">
                { alertState.alert }
                <Container>
                    <Row>
                        <Col className="mr-auto" lg="4" md="6">
                            <Card className="card-signup text-center">
                                <CardHeader>
                                    <CardTitle tag="h4">Sign up</CardTitle>
                                    <div className="social">
                                        <p className="card-description">Sign up to our newsletter!</p>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Form action="" className="form" method="">
                                            <InputGroup className={formState.firstNameState}>
                                                <InputGroup addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="nc-icon nc-single-02" />
                                                    </InputGroupText>
                                                </InputGroup>
                                                <Input
                                                    placeholder="First Name..."
                                                    type="text"
                                                    value={contactInfo.firstName}
                                                    onChange={(e) => onFieldChange(e, 'firstName', 'length')}
                                                />
                                                {formState.firstNameState === "has-danger" ? (
                                                    <label className="error">
                                                        This field is required.
                                                    </label>
                                                ) : null}
                                            </InputGroup>
                                        <InputGroup>
                                            <InputGroup addonType="prepend">
                                                <InputGroupText>
                                                    <i className="nc-icon nc-circle-10" />
                                                </InputGroupText>
                                            </InputGroup>
                                            <Input
                                                placeholder="Last Name..."
                                                type="text"
                                                value={contactInfo.lastName}
                                                onChange={(e) => setContactInfo(
                                                    new NewsletterContact({
                                                        ...contactInfo.toObject(),
                                                        lastName: e.target.value
                                                    })
                                                )}
                                            />
                                        </InputGroup>
                                        <InputGroup className={formState.emailState}>
                                            <InputGroup addonType="prepend">
                                                <InputGroupText>
                                                    <i className="nc-icon nc-email-85" />
                                                </InputGroupText>
                                            </InputGroup>
                                            <Input
                                                placeholder="Email..."
                                                type="email"
                                                value={contactInfo.email}
                                                onChange={(e) => onFieldChange(e, 'email', 'email')}

                                            />
                                            {formState.emailState === "has-danger" ? (
                                                <label className="error">
                                                    Please enter a valid email address.
                                                </label>
                                            ) : null}
                                        </InputGroup>
                                        <InputGroup>
                                            <InputGroup addonType="prepend">
                                                <InputGroupText>
                                                    <i className="nc-icon nc-laptop" />
                                                </InputGroupText>
                                            </InputGroup>
                                            <Input
                                                placeholder="Job title..."
                                                type="text"
                                                value={contactInfo.jobTitle}
                                                onChange={(e) => setContactInfo(
                                                    new NewsletterContact({
                                                        ...contactInfo.toObject(),
                                                        jobTitle: e.target.value
                                                    })
                                                )}
                                            />
                                        </InputGroup>
                                        <InputGroup className={formState.companyState}>
                                            <InputGroup addonType="prepend">
                                                <InputGroupText>
                                                    <i className="nc-icon nc-globe" />
                                                </InputGroupText>
                                            </InputGroup>
                                            <Input
                                                placeholder="Company Name..."
                                                type="text"
                                                value={contactInfo.company}
                                                onChange={(e) => onFieldChange(e, 'company', 'length')}
                                            />
                                            {formState.companyState === "has-danger" ? (
                                                <label className="error">
                                                    This field is required.
                                                </label>
                                            ) : null}
                                        </InputGroup>
                                        <FormGroup check className="text-left">
                                            <Label check>
                                                <Input
                                                    checked={conditionsState}
                                                    type="checkbox"
                                                    onChange={(e => setConditionsState(e.target.checked))}
                                                />
                                                <span className="form-check-sign" />I agree to the{" "}
                                                <a onClick={(e) => e.preventDefault()}>
                                                    terms and conditions
                                                </a>
                                                .
                                            </Label>
                                        </FormGroup>
                                    </Form>
                                </CardBody>
                                <CardFooter>
                                    <Button
                                        className="btn-round"
                                        color="info"
                                        onClick={subscribe}
                                    >
                                        Register
                                    </Button>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ToastContainer/>
            </div>
        </>
    )
}

export default RegisterNewsletter;